import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AESencrypt } from "./AESEncoder";
import { TextField } from "@material-ui/core";
// Backend
import config from "./config.json";
import { deleteCookie } from "./CookieManagement.js";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function VerifyEmail(props) {
  const history = useHistory();
  let [state, setState] = useState({
    password: "",
    successstate: false,
  });
  let [validstate, setValidstate] = useState({
    password: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    /*if (name === "password") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }*/
  };

  let query = new URLSearchParams(props.location.search);
  /*async function verify() {
    let Auth = query.get("Auth");
    let response = await fetch(config.backend_url + "/be/verify?Auth=" + Auth, {
      method: "GET",
      mode: "cors",
    });
    //
    if (response.status === 200) {
      document.getElementById("title").innerHTML = "Congratulations!!!";
      document.getElementById("content").innerHTML =
        "Your Account is Activated";
    } else {
      document.getElementById("title").innerHTML = "Please try again later...";
      document.getElementById("content").innerHTML =
        "Sorry, we cannot activare your account. Please try again later.";
    }
  }*/

  async function verifyEmail() {
    let Auth = query.get("Auth");
    let response = await fetch(
      config.backend_url + "/be/changeemailverification?Auth=" + Auth,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "text/plain",
        },
        body: AESencrypt(
          JSON.stringify({
            Auth: Auth,
            userpwd: state.password,
          }),
          config.aes_key
        ),
      }
    );

    if (response.status === 200) {
      // Show Message Calidation Success and click OK To redirect to https://bseviot.com/
      setState((prevstate) => ({
        ...prevstate,
        successstate: true,
      }));
    } else {
      // Show Error Message
      setState((prevstate) => ({
        ...prevstate,
        successstate: false,
      }));
      document.getElementById("passwordwarning").innerHTML = "Please Try Again";
    }
  }

  useEffect(() => {
    deleteCookie("bsetok");
    //verify();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div className="background">
      <div className="container home-block">
        {!state.successstate ? (
          <div className="home-card">
            <div className="text-title">Verification</div>
            <div>Please verify your account to change your email address.</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="password"
                  value={state.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="space-text">
              <div
                className="text-option"
                id="passwordwarning"
                style={{ color: "red" }}
              ></div>
            </div>
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={verifyEmail}
            >
              <div className="button-text-white">Verify</div>
            </div>
          </div>
        ) : (
          <div className="home-card">
            <CheckCircleIcon
              style={{
                fontSize: "64px",
                width: "100%",
                margin: "auto",
                color: "#49AA19",
              }}
            />
            <div
              style={{
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Congratulations!
            </div>
            <div
                style={{
                  marginBottom: "16px",
                  padding: "16px",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
               Change your email address successfully
              </div>
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={() => history.replace("/")}
            >
              <div className="button-text-white">OK</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifyEmail;
