import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// Backend
import config from "./config.json";
import { deleteCookie } from "./CookieManagement.js";
function Verify(props) {
  const history = useHistory();
  let query = new URLSearchParams(props.location.search);
  async function verify() {
    let Auth = query.get("Auth");
    let response = await fetch(config.backend_url + "/be/verify?Auth=" + Auth, {
      method: "GET",
      mode: "cors",
    });
    //
    if (response.status === 200) {
      document.getElementById("title").innerHTML = "Congratulations!!!";
      document.getElementById("content").innerHTML =
        "Your Account is Activated";
    } else {
      document.getElementById("title").innerHTML = "Please try again later...";
      document.getElementById("content").innerHTML =
        "Sorry, we cannot activate your account. Please try again later.";
    }
  }
  useEffect(() => {
    deleteCookie("bsetok");
    verify();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div className="background">
      <div className="container home-block">
        <div className="home-card">
          <div className="text-title" id="title">
            Waiting...
          </div>
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
              id="content"
            >
              Please wait...
            </div>
          </div>
          <div
            className="button"
            style={{ cursor: "pointer" }}
            onClick={() => history.replace("/")}
          >
            <div className="button-text-white">OK</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verify;
