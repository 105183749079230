import "./../../App.css";
import { useHistory } from "react-router-dom";
import "../Main.css";
import { IconButton } from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function GroupListCard(props) {
  const { title, icon, groupname, description } = props;
  const history = useHistory();

  return (
    // <div className="row">
    <div className="col-sm-12" style={{ marginBottom: "10px" }}>
      <div
        className="white-block"
        onClick={() => {
          localStorage.setItem("groupname", groupname);
          history.push("/dashboard");
        }}
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "8px 8px 4px 4px",
        }}
      >
        <div style={{ width: "auto" }}>{icon}</div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "left",
            alignItems: "flex-start",
            flex: 1,
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <div
            className="text-status-title"
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </div>
          {/* <div className="text-status-status">
                {status != null ? "Serial ID: " + status : undefined}
              </div> */}
        </div>

        <IconButton
          style={{
            backgroundColor: "#eeeeee",
            borderRadius: "32px",
            width: "32px",
            height: "32px",
          }}
        >
          <ArrowForwardIosIcon style={{ width: "16px", height: "16px" }} />
        </IconButton>
      </div>
      {description !== "" && (
        <div
          className="white-block"
          style={{
            background: "#eeeeee",
            borderTop: "1px dashed #aaaaaa",
            borderRadius: "4px 4px 8px 8px",
          }}
        >
          <div
            className="text-10 b-500 t-secondary a-left"
            style={{ marginLeft: "8px", marginRight: "8px" }}
          >
            {description}
          </div>
        </div>
      )}
    </div>
    // </div>
  );
}

export default GroupListCard;
