import "./../../App.css";
import React from "react";
import "./../Main.css";

import {
  ControlComponent,
  DeviceTab,
  DeviceTitle,
  DeviceFooter,
  OnChartLoad,
  MonitorLogs,
} from "./Globalfn.js";

// import SensorsIcon from "@material-ui/icons/Sensors";

import {
  StatusPropCard,
  StatusSliderCard,
  StatusLightCardNew,
} from "../minicomponents";
import Chart from "react-apexcharts";

import { Select } from "@material-ui/core";
import { AESdecrypt } from "./../AESEncoder";
// backend
import config from "../config.json";

var strftime = require("strftime");
let mqtt = require("mqtt");
export default class DeviceBSE4A1FIOT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.userdata.serialposition[0],
      plotselection: "Realtime",
      plotlinedata: {
        Realtime: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },

        t1hr: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },
        t1day: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },
        t7day: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },
        t1month: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },
        t3month: {
          tempAvg: [],
          fTemp1: [],
          fTemp2: [],
          fHumi: [],
          stAIR1: [],
          stAIR2: [],
          stAIR3: [],
          stAIR4: [],
          time: [],
        },
      },
      control: props.userdata.serialposition[0].input,
      select: "historical",
      serialid: props.serialid,
      isdataloading: false,

      mode_orders: [
        "A1",
        "A2",
        "A3",
        "A4",
        "A5",
        "A6",
        "A7",
        "b1",
        "b2",
        "b3",
        "b4",
        "C1",
        "C2",
        "C3",
        "C4",
      ],

      timeAir1Rules: {
        1: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24,
        ],
        2: [1, 2, 3, 4, 6, 12], // A2
        3: [1, 2, 3, 4, 6, 12], // A3
        4: [1, 2, 3, 4, 6, 12], // A4
        5: [1, 2, 3, 4, 6, 12], // A5
        6: [1, 2, 3, 4, 6, 12], // A6
        7: [1, 2, 3, 4, 6, 12], // A7
        8: [1, 2, 4, 8], // b1
        9: [1, 2, 4, 8], // b2
        10: [1, 2, 4, 8], // b3
        11: [1, 2, 4, 8], // b4
        12: [1, 2, 3, 4, 6, 12], // C1
        13: [1, 2, 3, 4, 6, 12], // C2
        14: [1, 2, 3, 4, 6, 12], // C3
        15: [1, 2, 3, 6], // C4
      },
    };
  }
  async gettimeseriesdata(serialid) {
    let response = await fetch(
      config.backend_url +
        "/getdevicedata?serialid=" +
        serialid +
        "&query_sensor=tempAvg,fTemp1,fTemp2,fHumi,stAIR1,stAIR2,stAIR3,stAIR4",
      {
        method: "GET",
        mode: "cors",
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      Object.keys(data).map((i) => {
        this.state.plotlinedata[i] = data[i];
        return "";
      });
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        isdataloading: true,
      }));
    } else if (response.status === 404) {
      this.setState((prevstate) => ({
        ...prevstate,
        isdataloading: true,
      }));
    }
  }

  handleChange_select = (event, newValue) => {
    if (newValue === "control") {
      this.handleChange_refresh();
    }
    this.setState((prevstate) => ({ ...prevstate, select: newValue }));
  };
  async handleChange_control(name, newValue) {
    let dummy_data = this.state.control;
    let old_value = dummy_data[name];
    dummy_data[name] = newValue;
    if (name === "returnTemp") {
      if (
        dummy_data.returnTemp + dummy_data.lowTemp + 1 > dummy_data.highTemp &&
        dummy_data.returnTemp + dummy_data.lowTemp + 1 <= 50
      ) {
        dummy_data.highTemp = dummy_data.returnTemp + dummy_data.lowTemp + 1;
      } else if (
        dummy_data.returnTemp + dummy_data.lowTemp + 1 >
        dummy_data.highTemp
      ) {
        dummy_data.lowTemp = 50 - dummy_data.returnTemp - 1;
      }
      if (dummy_data.highTemp - dummy_data.returnTemp < dummy_data.lowTemp) {
        dummy_data.lowTemp = dummy_data.highTemp - dummy_data.returnTemp;
      }
    } else if (name === "highTemp") {
      if (newValue - dummy_data.returnTemp < dummy_data.lowTemp + 1) {
        dummy_data.lowTemp = dummy_data.highTemp - dummy_data.returnTemp;
      }
    } else if ((name === "highHumi") | (name === "returnHumi")) {
      if (dummy_data.selHumi === 0) {
        dummy_data[name] = old_value;
      }
    } else if ((name === "modeAir") & (newValue !== 1)) {
      // Check timeAir1
      if (dummy_data.slTimeAir === 1) {
        if (this.state.timeAir1Rules[newValue].includes(dummy_data.timeAir1)) {
        } else {
          if (newValue === 15) {
            dummy_data.timeAir1 = 6;
          } else {
            dummy_data.timeAir1 = 4;
          }
        }
      }
    } else if (name === "slTimeAir") {
      if (newValue === 1) {
        if (dummy_data.modeAir === 15) {
          dummy_data.timeAir1 = 6;
        } else {
          dummy_data.timeAir1 = 4;
        }
        dummy_data.timeAir2 = 0;
      } else {
        dummy_data.timeAir2 = 1;
        dummy_data.timeAir1 = 0;
      }
    }

    if (this.props.userdata.controlaccess.includes(this.state.serialid)) {
      this.setState((prevstate) => ({ ...prevstate, control: dummy_data }));
    }
  }
  handleChange_refresh = () => {
    let default_data = { ...this.state.data.input };
    this.setState((prevstate) => ({ ...prevstate, control: default_data }));
  };

  componentDidMount() {
    const clientId =
      "reactuser_" +
      this.props.userdata.username +
      (Math.floor(Math.random() * 1000000) + 1).toString();
    const options = {
      keepalive: 60,
      username: config.mqtt_username,
      password: config.mqtt_pwd,
      clientId: clientId,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
    };
    const client = mqtt.connect(config.mqtt_endpoint, options);
    client.on("error", (err) => {
      console.log("Connection error: ", err);
      client.end();
    });

    client.on("reconnect", () => {
      // console.log("Reconnecting...");
    });
    client.subscribe("updatedata/" + this.props.serialid + "/+", { qos: 0 });
    client.subscribe("updatedatas/" + this.props.serialid + "/+", { qos: 0 });
    client.on("message", (topic, message, packet) => {
      let data = message.toString();
      if (topic.split("/")[0] === "updatedatas") {
        data = AESdecrypt(data, config.device_aes_key);
      }
      data = JSON.parse(data);
      data.lat = parseFloat(data.lat);
      data.lgn = parseFloat(data.lgn);
      let currenttime = strftime(
        "%F %T",
        new Date(
          Date.now() +
            new Date().getTimezoneOffset() * 60 * 1000 +
            7 * 60 * 60 * 1000
        )
      );
      this.state.plotlinedata.Realtime.tempAvg.push(data.sensor.tempAvg);
      this.state.plotlinedata.Realtime.fTemp1.push(data.sensor.fTemp1);
      this.state.plotlinedata.Realtime.fTemp2.push(data.sensor.fTemp2);
      this.state.plotlinedata.Realtime.fHumi.push(data.sensor.fHumi);
      this.state.plotlinedata.Realtime.stAIR1.push(data.sensor.stAIR1);
      this.state.plotlinedata.Realtime.stAIR2.push(data.sensor.stAIR2);
      this.state.plotlinedata.Realtime.stAIR3.push(data.sensor.stAIR3);
      this.state.plotlinedata.Realtime.stAIR4.push(data.sensor.stAIR4);
      this.state.plotlinedata.Realtime.time.push(currenttime);
      while (
        new Date(currenttime) -
          new Date(this.state.plotlinedata.Realtime.time[0]) >=
        1000 * 60 * 3 // 3 min
      ) {
        this.state.plotlinedata.Realtime.tempAvg.shift();
        this.state.plotlinedata.Realtime.fTemp1.shift();
        this.state.plotlinedata.Realtime.fTemp2.shift();
        this.state.plotlinedata.Realtime.fHumi.shift();
        this.state.plotlinedata.Realtime.stAIR1.shift();
        this.state.plotlinedata.Realtime.stAIR2.shift();
        this.state.plotlinedata.Realtime.stAIR3.shift();
        this.state.plotlinedata.Realtime.stAIR4.shift();
        this.state.plotlinedata.Realtime.time.shift();
      }
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        data: data,
      }));
    });
    MonitorLogs(this.state.serialid);
    this.gettimeseriesdata(this.state.serialid);
  }

  render() {
    return (
      <div className="device-card">
        <DeviceTab
          state={this.state}
          handleChange_select={this.handleChange_select}
        />
        {this.state.select === "control" && (
          <div className="data-card">
            {!this.props.userdata.controlaccess.includes(
              this.state.serialid
            ) && (
              <div style={{ width: "100%" }}>
                You are not allowed to control this device.
              </div>
            )}
            <div className="row">
              <div className="col-sm-6">
                <StatusPropCard
                  title={"Mode"}
                  // status={this.state.control.modeAir}
                  icon={
                    <img
                      src="/customicons/mode.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="custom"
                  color="primary"
                  custom={
                    <Select
                      native
                      value={this.state.control.modeAir.toString()}
                      onChange={(e) => {
                        this.handleChange_control(
                          "modeAir",
                          parseInt(e.target.value)
                        );
                      }}
                    >
                      <option value="1">A1 = Manual</option>
                      <option value="2">A2 = AIR1-AIR2</option>
                      <option value="3">A3 = AIR1-AIR3</option>
                      <option value="4">A4 = AIR1-AIR4</option>
                      <option value="5">A5 = AIR2-AIR3</option>
                      <option value="6">A6 = AIR2-AIR4</option>
                      <option value="7">A7 = AIR3-AIR4</option>
                      <option value="8">b1 = AIR1-AIR2-AIR3</option>
                      <option value="9">b2 = AIR2-AIR3-AIR4</option>
                      <option value="10">b3 = AIR1-AIR3-AIR4</option>
                      <option value="11">b4 = AIR2-AIR3-AIR4</option>
                      <option value="12">C1 = AIR1-AIR2-AIR3-AIR4</option>
                      <option value="13">C2 = AIR1-AIR3/AIR2-AIR4</option>
                      <option value="14">C3 = AIR1-AIR4/AIR2-AIR3</option>
                      <option value="15">C4 = AIR1-AIR2/AIR3-AIR4</option>
                    </Select>
                  }
                />
                {this.state.control.modeAir !== 1 && (
                  <div>
                    <StatusPropCard
                      title={"Select Time Air"}
                      // status={this.state.control.modeAir}
                      icon={
                        <img
                          src="/customicons/timeicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="custom"
                      color="primary"
                      custom={
                        <Select
                          native
                          value={this.state.control.slTimeAir.toString()}
                          onChange={(e) => {
                            this.handleChange_control(
                              "slTimeAir",
                              parseInt(e.target.value)
                            );
                          }}
                        >
                          <option value="1">F1</option>
                          <option value="2">F2</option>
                        </Select>
                      }
                    />
                    {this.state.control.slTimeAir === 2 && (
                      <StatusSliderCard
                        title="Set Time Air"
                        onChange={(e, newValue) =>
                          this.handleChange_control("timeAir2", newValue)
                        }
                        value={this.state.control.timeAir2}
                        step={1}
                        min={1}
                        max={24}
                        clsName="text-status-step"
                        color="#326886"
                        decscale={2}
                      />
                    )}
                    {this.state.control.slTimeAir === 1 && (
                      <StatusPropCard
                        title={"Set Time Air"}
                        mode="custom"
                        color="primary"
                        custom={
                          <Select
                            native
                            value={this.state.control.timeAir1.toString()}
                            onChange={(e) => {
                              this.handleChange_control(
                                "timeAir1",
                                parseInt(e.target.value)
                              );
                            }}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            {[2, 3, 4, 5, 6, 7, 12, 13, 14, 15].includes(
                              this.state.control.modeAir
                            ) && <option value="3">3</option>}
                            {this.state.control.modeAir !== 15 && (
                              <option value="4">4</option>
                            )}
                            {[2, 3, 4, 5, 6, 7, 12, 13, 14, 15].includes(
                              this.state.control.modeAir
                            ) && <option value="6">6</option>}
                            {[8, 9, 10, 11].includes(
                              this.state.control.modeAir
                            ) && <option value="8">8</option>}
                            {[2, 3, 4, 5, 6, 7, 12, 13, 14].includes(
                              this.state.control.modeAir
                            ) && <option value="12">12</option>}
                          </Select>
                        }
                      />
                    )}
                  </div>
                )}
                {this.state.control.modeAir === 1 && (
                  <div>
                    <StatusPropCard
                      title="Air1"
                      status={this.state.control.stAir1 === 1 ? "ON" : "OFF"}
                      icon={
                        <img
                          src="/customicons/airicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="switch"
                      color="primary"
                      onChange={(e, newValue) =>
                        this.handleChange_control("stAir1", newValue ? 1 : 0)
                      }
                      value={this.state.control.stAir1 === 1}
                    />
                    <StatusPropCard
                      title="Air2"
                      status={this.state.control.stAir2 === 1 ? "ON" : "OFF"}
                      icon={
                        <img
                          src="/customicons/airicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="switch"
                      color="primary"
                      onChange={(e, newValue) =>
                        this.handleChange_control("stAir2", newValue ? 1 : 0)
                      }
                      value={this.state.control.stAir2 === 1}
                    />
                    <StatusPropCard
                      title="Air3"
                      status={this.state.control.stAir3 === 1 ? "ON" : "OFF"}
                      icon={
                        <img
                          src="/customicons/airicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="switch"
                      color="primary"
                      onChange={(e, newValue) =>
                        this.handleChange_control("stAir3", newValue ? 1 : 0)
                      }
                      value={this.state.control.stAir3 === 1}
                    />
                    <StatusPropCard
                      title="Air4"
                      status={this.state.control.stAir4 === 1 ? "ON" : "OFF"}
                      icon={
                        <img
                          src="/customicons/airicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="switch"
                      color="primary"
                      onChange={(e, newValue) =>
                        this.handleChange_control("stAir4", newValue ? 1 : 0)
                      }
                      value={this.state.control.stAir4 === 1}
                    />
                    <StatusPropCard
                      title="Fan"
                      status={this.state.control.stFan === 1 ? "ON" : "OFF"}
                      icon={
                        <img
                          src="/customicons/fanicon.png"
                          style={{ width: "30px", height: "30px" }}
                          alt=""
                        />
                      }
                      mode="switch"
                      color="primary"
                      onChange={(e, newValue) =>
                        this.handleChange_control("stFan", newValue ? 1 : 0)
                      }
                      value={this.state.control.stFan === 1}
                    />
                  </div>
                )}
              </div>
              <div className="col-sm-6">
                <StatusPropCard
                  title={"Select Temperature"}
                  // status={this.state.control.selSensor}
                  icon={
                    <img
                      src="/customicons/TempIcon.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="custom"
                  color="primary"
                  custom={
                    <Select
                      native
                      value={this.state.control.selSensor.toString()}
                      onChange={(e) => {
                        this.handleChange_control(
                          "selSensor",
                          parseInt(e.target.value)
                        );
                      }}
                    >
                      <option value="1">Sensor1</option>
                      <option value="2">Sensor2</option>
                      <option value="3">{"Sensor1&2"}</option>
                    </Select>
                  }
                />
                <StatusPropCard
                  title="Humidity"
                  status={this.state.control.selHumi === 1 ? "ON" : "OFF"}
                  icon={
                    <img
                      src="/customicons/HumidIcon.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="primary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("selHumi", newValue ? 1 : 0)
                  }
                  value={this.state.control.selHumi === 1}
                />
                <div
                  onClick={() => {
                    // window.open("https://www.google.com/maps/search/");
                  }}
                >
                  <StatusPropCard
                    title={"Manual"}
                    icon={
                      <img
                        src="/customicons/manualicon.png"
                        style={{ width: "30px", height: "30px" }}
                        alt=""
                      />
                    }
                    mode="custom"
                    color="primary"
                    custom={<div></div>}
                  />
                </div>
              </div>
            </div>
            <div
              className="text-title2"
              style={{ marginTop: "4px", marginBottom: "4px" }}
            >
              Setting Air
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set High Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("highTemp", newValue)
                      }
                      value={this.state.control.highTemp}
                      step={1}
                      min={
                        this.state.control.lowTemp +
                        this.state.control.returnTemp
                      }
                      max={50}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Low Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("lowTemp", newValue)
                      }
                      value={this.state.control.lowTemp}
                      step={1}
                      min={10}
                      max={
                        this.state.control.highTemp -
                        this.state.control.returnTemp
                      }
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Delay Start"
                      onChange={(e, newValue) =>
                        this.handleChange_control("delayStart", newValue)
                      }
                      value={this.state.control.delayStart}
                      step={1}
                      min={1}
                      max={5}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Return Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("returnTemp", newValue)
                      }
                      value={this.state.control.returnTemp}
                      step={1}
                      min={1}
                      max={5}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Time Step"
                      onChange={(e, newValue) =>
                        this.handleChange_control("timeStep", newValue)
                      }
                      value={this.state.control.timeStep}
                      step={1}
                      min={1}
                      max={60}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Time Check"
                      onChange={(e, newValue) =>
                        this.handleChange_control("timeCheck", newValue)
                      }
                      value={this.state.control.timeCheck}
                      step={1}
                      min={5}
                      max={30}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Over High Time"
                      onChange={(e, newValue) =>
                        this.handleChange_control("overHT", newValue)
                      }
                      value={this.state.control.overHT}
                      step={1}
                      min={5}
                      max={30}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Overlap Time"
                      onChange={(e, newValue) =>
                        this.handleChange_control("overlapTime", newValue)
                      }
                      value={this.state.control.overlapTime}
                      step={1}
                      min={1}
                      max={15}
                      clsName="text-status-step"
                      color="#326886"
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="High Humidity"
                      onChange={(e, newValue) =>
                        this.handleChange_control("highHumi", newValue)
                      }
                      value={this.state.control.highHumi}
                      step={5}
                      min={50}
                      max={95}
                      clsName="text-status-step"
                      color={
                        this.state.control.selHumi === 1 ? "#326886" : "#999999"
                      }
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Return Humidity"
                      onChange={(e, newValue) =>
                        this.handleChange_control("returnHumi", newValue)
                      }
                      value={this.state.control.returnHumi}
                      step={5}
                      min={5}
                      max={50}
                      clsName="text-status-step"
                      color={
                        this.state.control.selHumi === 1 ? "#326886" : "#999999"
                      }
                      decscale={2}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6"></div>
              <div className="col-sm-6">
                <ControlComponent
                  state={this.state}
                  handleChange_refresh={this.handleChange_refresh}
                  props={this.props}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.select === "historical" && (
          <div className="data-card">
            <DeviceTitle
              state={this.state}
              props={this.props}
              onSelectChange={(e) => {
                this.setState((prevstate) => ({
                  ...prevstate,
                  plotselection: e.target.value,
                }));
              }}
              onChartRefresh={() => {
                this.gettimeseriesdata(this.state.serialid);
              }}
            />
            {/* Linechart HERE */}
            {this.state.plotlinedata[this.state.plotselection].time.length !==
            0 ? (
              <div
                //className="main chart-wrapper"
                style={{
                  width: "100%",
                  height: "900px",
                  display: "inline-block",
                  marginBottom: "20px",
                }}
              >
                <div id="chartwrapper">
                  <div
                    id="chart-temp1"
                    style={{
                      //overflowX: "scroll",
                      //alignItems: "center",
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].tempAvg.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp Avg",
                        },
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].fTemp1.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp1",
                        },
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].fTemp2.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp2",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#f81900", "#fcd41d", "#fa8e0a"],
                        stroke: {
                          curve: ["straight", "straight", "straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp2"
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].fHumi.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Humidity",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#0f3c8f"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-stAIR1"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].stAIR1.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Status Air1",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "stAIR1",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#00E396"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else if (value === 2) {
                                  return "Failed";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-stAIR2"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].stAIR2.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Status Air2",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "stAIR2",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#3dc0e8"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else if (value === 2) {
                                  return "Failed";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-stAIR3"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].stAIR3.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Status Air3",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "stAIR3",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#e7d538"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else if (value === 2) {
                                  return "Failed";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-stAIR4"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].stAIR4.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Status Air4",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "stAIR4",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#e3aa1c"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else if (value === 2) {
                                  return "Failed";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <OnChartLoad state={this.state} />
            )}
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="status-show-block">
                    <div className="text-status-status">MODE</div>
                    {this.state.data && (
                      <div
                        className="text-status-ref"
                        style={{ color: "#326886" }}
                      >
                        {
                          this.state.mode_orders[
                            this.state.data.input.modeAir - 1
                          ]
                        }
                      </div>
                    )}
                    <div className="text-status-status">
                      {
                        [
                          "Manual",
                          "1 ON - 1 OFF",
                          "1 ON - 1 OFF",
                          "1 ON - 1 OFF",
                          "1 ON - 1 OFF",
                          "1 ON - 1 OFF",
                          "1 ON - 1 OFF",
                          "2 ON - 1 OFF",
                          "2 ON - 1 OFF",
                          "2 ON - 1 OFF",
                          "2 ON - 1 OFF",
                          "2 ON - 2 OFF",
                          "2 ON - 2 OFF",
                          "2 ON - 2 OFF",
                          "2 ON - 2 OFF",
                        ][this.state.data.input.modeAir - 1]
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/TempIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div
                          className="text-status-ref"
                          style={{ color: "#326886" }}
                        >
                          {this.state.data.sensor.tempAvg}°c
                        </div>
                      )}
                      <div className="text-status-status">Temperature Avg</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/HumidIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div
                          className="text-status-ref"
                          style={{ color: "#326886" }}
                        >
                          {this.state.data.sensor.fHumi}
                        </div>
                      )}
                      <div className="text-status-status">Humidity</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="status-show-block">
                    {this.state.data && (
                      <div
                        className="text-status-ref"
                        style={{ color: "#326886" }}
                      >
                        {this.state.data.sensor.cdTimeAir}
                      </div>
                    )}
                    <div className="text-status-status">COUNTDOWN TIME AIR</div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="status-block-new">
                  <div className="text-subtitle" style={{ margin: "0px" }}>
                    Air and Fan Status
                  </div>
                  <div
                    className="row"
                    style={{ flex: 1, justifyContent: "space-between" }}
                  >
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="AIR1"
                      color={
                        this.state.data.sensor.stAIR1 === 0 ? (
                          <div className="status-light-gray"></div>
                        ) : this.state.data.sensor.stAIR1 === 1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-red"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="AIR2"
                      color={
                        this.state.data.sensor.stAIR2 === 0 ? (
                          <div className="status-light-gray"></div>
                        ) : this.state.data.sensor.stAIR2 === 1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-red"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="AIR3"
                      color={
                        this.state.data.sensor.stAIR3 === 0 ? (
                          <div className="status-light-gray"></div>
                        ) : this.state.data.sensor.stAIR3 === 1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-red"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="AIR4"
                      color={
                        this.state.data.sensor.stAIR4 === 0 ? (
                          <div className="status-light-gray"></div>
                        ) : this.state.data.sensor.stAIR4 === 1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-red"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="FAN"
                      color={
                        this.state.data.sensor.stFAN === 0 ? (
                          <div className="status-light-gray"></div>
                        ) : this.state.data.sensor.stFAN === 1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-red"></div>
                        )
                      }
                    />
                  </div>
                </div>
                <div className="status-block-new">
                  <div className="text-subtitle" style={{ margin: "0px" }}>
                    Status Alarm
                  </div>
                  <div
                    className="row"
                    style={{ flex: 1, justifyContent: "space-between" }}
                  >
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="High Temp"
                      color={
                        this.state.data.sensor.stHighTemp ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="High Humidity"
                      color={
                        this.state.data.sensor.stHighHumi ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Phase Fail"
                      color={
                        this.state.data.sensor.stPhase ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Low Temp"
                      color={
                        this.state.data.sensor.stLowtemp ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Sensor Error"
                      color={
                        this.state.data.sensor.stSensor ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Fire Alarm"
                      color={
                        this.state.data.sensor.stFireAlarm ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <DeviceFooter state={this.state} props={this.props} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
