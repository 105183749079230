import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

function OpenMapcomponent(props) {

  let markers = props.markers; //[{ lat: 13.74, lng: 100.5 }];
  let center = [13.58, 101.31];
  let zoomstart = props.zoomstart;
  return (
    <div>
      <MapContainer
        center={center}
        zoom={zoomstart}
        scrollWheelZoom={false}
        style={{ height: "60vh", width: "100wh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {markers.map((marker, index) => (
          <Marker
            position={[marker.lat, marker.lng]}
            icon={
              new L.icon({
                iconUrl: props.logo[index],
                iconSize: [50, 50],
                iconAnchor: [25, 50],
              })
            }
          >
            <Popup>
              <h6>{props.Devicename[index]}</h6>
              <p>Serialid: {props.DeviceType[index]}</p>
              <a href={"/device?serialid=" + props.DeviceType[index]}>
                Go to Dashboard
              </a>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
}

export default OpenMapcomponent;
