import "./../../App.css";
import "../Main.css";

function StatusLightCardNew(props) {
  const { title, color } = props;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minWidth: "120px",
        flex: 1,
        marginTop: "10px",
        marginBottom: "10px",
      }}
    >
      <div className="status-box">{color}</div>
      <div className="text-status-title-led">{title}</div>
    </div>
  );
}

export default StatusLightCardNew;
