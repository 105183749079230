import "./../../App.css";
import React from "react";
import "./../Main.css";

import {
  ControlComponent,
  DeviceTab,
  DeviceTitle,
  DeviceFooter,
  OnChartLoad,
  MonitorLogs,
} from "./Globalfn.js";

import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import VibrationIcon from "@material-ui/icons/Vibration";

import {
  StatusPropCard,
  StatusSliderCard,
  StatusLightCardNew,
} from "../minicomponents";
import Chart from "react-apexcharts";
import { AESdecrypt } from "./../AESEncoder";
// backend
import config from "../config.json";

var strftime = require("strftime");
let mqtt = require("mqtt");
export default class DeviceBSESIMCOWV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.userdata.serialposition[0],
      plotselection: "Realtime",
      plotlinedata: {
        Realtime: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },

        t1hr: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },
        t1day: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },
        t7day: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },
        t1month: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },
        t3month: {
          tempin: [],
          tempout: [],
          humidin: [],
          humidout: [],
          wind: [],
          car: [],
          x: [],
          y: [],
          vibration: [],
          pm: [],
          rain: [],
          floodlevel: [],
          time: [],
        },
      },
      control: props.userdata.serialposition[0].input,
      select: "historical",
      serialid: props.serialid,
      isdataloading: false,
    };
  }
  async gettimeseriesdata(serialid) {
    let response = await fetch(
      config.backend_url +
        "/getdevicedata?serialid=" +
        serialid +
        "&query_sensor=tempin,tempout,humidin,humidout,wind,car,x,y,vibration,pm,rain,floodlevel",
      {
        method: "GET",
        mode: "cors",
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      Object.keys(data).map((i) => {
        this.state.plotlinedata[i] = data[i];
        return "";
      });
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        isdataloading: true,
      }));
    } else if (response.status === 404) {
      this.setState((prevstate) => ({
        ...prevstate,
        isdataloading: true,
      }));
    }
  }

  handleChange_select = (event, newValue) => {
    if (newValue === "control") {
      this.handleChange_refresh();
    }
    this.setState((prevstate) => ({ ...prevstate, select: newValue }));
  };
  async handleChange_control(name, newValue) {
    let dummy_data = this.state.control;
    dummy_data[name] = newValue;
    if (this.props.userdata.controlaccess.includes(this.state.serialid)) {
      this.setState((prevstate) => ({ ...prevstate, control: dummy_data }));
    }
  }
  handleChange_refresh = () => {
    let default_data = { ...this.state.data.input };
    this.setState((prevstate) => ({ ...prevstate, control: default_data }));
  };

  componentDidMount() {
    const clientId =
      "reactuser_" +
      this.props.userdata.username +
      (Math.floor(Math.random() * 1000000) + 1).toString();
    const options = {
      keepalive: 60,
      username: config.mqtt_username,
      password: config.mqtt_pwd,
      clientId: clientId,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
    };
    const client = mqtt.connect(config.mqtt_endpoint, options);
    client.on("error", (err) => {
      console.log("Connection error: ", err);
      client.end();
    });

    client.on("reconnect", () => {
      // console.log("Reconnecting...");
    });
    client.subscribe("updatedata/" + this.props.serialid + "/+", { qos: 0 });
    client.subscribe("updatedatas/" + this.props.serialid + "/+", { qos: 0 });
    client.on("message", (topic, message, packet) => {
      let data = message.toString();
      if (topic.split("/")[0] === "updatedatas") {
        data = AESdecrypt(data, config.device_aes_key);
      }
      data = JSON.parse(data);
      data.lat = parseFloat(data.lat);
      data.lgn = parseFloat(data.lgn);
      let currenttime = strftime(
        "%F %T",
        new Date(
          Date.now() +
            new Date().getTimezoneOffset() * 60 * 1000 +
            7 * 60 * 60 * 1000
        )
      );
      this.state.plotlinedata.Realtime.tempin.push(data.sensor.tempin);
      this.state.plotlinedata.Realtime.tempout.push(data.sensor.tempout);
      this.state.plotlinedata.Realtime.humidin.push(data.sensor.humidin);
      this.state.plotlinedata.Realtime.humidout.push(data.sensor.humidout);
      this.state.plotlinedata.Realtime.wind.push(data.sensor.wind);
      this.state.plotlinedata.Realtime.car.push(data.sensor.car);
      this.state.plotlinedata.Realtime.x.push(data.sensor.x);
      this.state.plotlinedata.Realtime.y.push(data.sensor.y);
      this.state.plotlinedata.Realtime.vibration.push(data.sensor.vibration);
      this.state.plotlinedata.Realtime.pm.push(data.sensor.pm);
      this.state.plotlinedata.Realtime.rain.push(data.sensor.rain);
      this.state.plotlinedata.Realtime.floodlevel.push(data.sensor.floodlevel);
      this.state.plotlinedata.Realtime.time.push(currenttime);
      while (
        new Date(currenttime) -
          new Date(this.state.plotlinedata.Realtime.time[0]) >=
        1000 * 60 * 3 // 3 min
      ) {
        this.state.plotlinedata.Realtime.tempin.shift();
        this.state.plotlinedata.Realtime.tempout.shift();
        this.state.plotlinedata.Realtime.humidin.shift();
        this.state.plotlinedata.Realtime.humidout.shift();
        this.state.plotlinedata.Realtime.wind.shift();
        this.state.plotlinedata.Realtime.car.shift();
        this.state.plotlinedata.Realtime.x.shift();
        this.state.plotlinedata.Realtime.y.shift();
        this.state.plotlinedata.Realtime.vibration.shift();
        this.state.plotlinedata.Realtime.pm.shift();
        this.state.plotlinedata.Realtime.rain.shift();
        this.state.plotlinedata.Realtime.floodlevel.shift();
        this.state.plotlinedata.Realtime.time.shift();
      }
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        data: data,
      }));
    });
    MonitorLogs(this.state.serialid);
    this.gettimeseriesdata(this.state.serialid);
  }

  render() {
    return (
      <div className="device-card">
        <DeviceTab
          state={this.state}
          handleChange_select={this.handleChange_select}
        />
        {this.state.select === "control" && (
          <div className="data-card">
            {!this.props.userdata.controlaccess.includes(
              this.state.serialid
            ) && (
              <div style={{ width: "100%" }}>
                You are not allowed to control this device.
              </div>
            )}
            <div className="row">
              <div className="col-sm-6">
                <StatusPropCard
                  title={this.state.control.mode ? "Auto" : "Manual"}
                  status={this.state.control.mode ? "Auto" : "Manual"}
                  icon={
                    <img
                      src="/customicons/mode.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="primary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("mode", !newValue ? 1 : 0)
                  }
                  value={!this.state.control.mode}
                />
                <StatusPropCard
                  title="Sound"
                  name="sound"
                  icon={<VolumeUpIcon />}
                  mode="switch"
                  color="primary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("sound", newValue ? 1 : 0)
                  }
                  value={this.state.control.sound}
                />
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Wind"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setwind", newValue)
                      }
                      value={this.state.control.setwind}
                      step={1}
                      min={10}
                      max={99}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Car"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setcar", newValue)
                      }
                      value={this.state.control.setcar}
                      step={1}
                      min={10}
                      max={130}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set X"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setx", newValue)
                      }
                      value={this.state.control.setx}
                      step={1}
                      min={2}
                      max={30}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Y"
                      onChange={(e, newValue) =>
                        this.handleChange_control("sety", newValue)
                      }
                      value={this.state.control.sety}
                      step={1}
                      min={2}
                      max={30}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Vibration"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setvibration", newValue)
                      }
                      value={this.state.control.setvibration}
                      step={1}
                      min={10}
                      max={100}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set PM"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setpm", newValue)
                      }
                      value={this.state.control.setpm}
                      step={1}
                      min={30}
                      max={200}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Rain"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setrain", newValue)
                      }
                      value={this.state.control.setrain}
                      step={1}
                      min={30}
                      max={200}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Flood Level"
                      onChange={(e, newValue) =>
                        this.handleChange_control("setfloodlevel", newValue)
                      }
                      value={this.state.control.setfloodlevel}
                      step={1}
                      min={10}
                      max={100}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>

                <ControlComponent
                  state={this.state}
                  handleChange_refresh={this.handleChange_refresh}
                  props={this.props}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.select === "historical" && (
          <div className="data-card">
            <DeviceTitle
              state={this.state}
              props={this.props}
              onSelectChange={(e) => {
                this.setState((prevstate) => ({
                  ...prevstate,
                  plotselection: e.target.value,
                }));
              }}
              onChartRefresh={() => {
                this.gettimeseriesdata(this.state.serialid);
              }}
            />
            {/* Linechart HERE */}
            {this.state.plotlinedata[this.state.plotselection].time.length !==
            0 ? (
              <div
                //className="main chart-wrapper"
                style={{
                  width: "100%",
                  height: "1450px",
                  display: "inline-block",
                  marginBottom: "20px",
                }}
              >
                <div id="chartwrapper">
                  <div
                    id="chart-temp1"
                    style={{
                      //overflowX: "scroll",
                      //alignItems: "center",
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].tempin.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp IN",
                        },
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].tempout.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp OUT",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#193cab", "#4e92f0"],
                        stroke: {
                          curve: ["straight", "straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp2"
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].humidin.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Humid IN",
                        },
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].humidout.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Humid OUT",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#ffa500", "#f3ea5f"],
                        stroke: {
                          curve: ["straight", "straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp3"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].wind.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Wind",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#00E396"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp4"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].car.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Car",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#f3ea5f"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-xy"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].x.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "X",
                        },
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].y.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Y",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#008FFB", "#ffa500"],
                        stroke: {
                          curve: ["straight", "straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp5"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].vibration.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Vibration",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#ff4560"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-pm"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].pm.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "PM",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#bd1996"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-rain"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].rain.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Rain",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#d2530e"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-flood-lv"
                    style={{
                      width: "100%",
                      height: "150px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].floodlevel.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Flood Level",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#168118"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <OnChartLoad state={this.state} />
            )}
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/TempIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.tempin}°c
                        </div>
                      )}
                      <div className="text-status-status">Temperature IN</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/TempIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.tempout}°c
                        </div>
                      )}
                      <div className="text-status-status">Temperature OUT</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/HumidIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.humidin}
                        </div>
                      )}
                      <div className="text-status-status">Humidity IN</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/HumidIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.humidout}
                        </div>
                      )}
                      <div className="text-status-status">Humidity OUT</div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/airicon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.wind}
                        </div>
                      )}
                      <div className="text-status-status">Wind</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <DriveEtaIcon
                        style={{ color: "#444444", width: "24px" }}
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.car}
                        </div>
                      )}
                      <div className="text-status-status">Car</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block-car">
                      <img
                        style={{
                          height: "100px",
                          width: "100px",
                          transform: `rotate(${this.state.data.sensor.x}deg)`,
                        }}
                        src="/customicons/car-front.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.x}°
                        </div>
                      )}
                      <div className="text-status-status">X</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block-car">
                      <img
                        style={{
                          height: "100px",
                          width: "100px",
                          transform: `rotate(${this.state.data.sensor.y}deg)`,
                        }}
                        src="/customicons/car-side.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.y}°
                        </div>
                      )}
                      <div className="text-status-status">Y</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <VibrationIcon
                        style={{ color: "#444444", width: "24px" }}
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.vibration}
                        </div>
                      )}
                      <div className="text-status-status">Vibration</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/dust-icon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.pm}
                        </div>
                      )}
                      <div className="text-status-status">PM</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/rain-icon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.rain}
                        </div>
                      )}
                      <div className="text-status-status">Rain</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/flood-icon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.floodlevel}
                        </div>
                      )}
                      <div className="text-status-status">Flood Level</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="status-block-new">
                  <div className="text-subtitle" style={{ margin: "0px" }}>
                    Status Alarm
                  </div>
                  <div
                    className="row"
                    style={{ flex: 1, justifyContent: "space-between" }}
                  >
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Wind"
                      color={
                        this.state.data.sensor.alwind ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Car"
                      color={
                        this.state.data.sensor.alcar ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm X"
                      color={
                        this.state.data.sensor.alx ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Y"
                      color={
                        this.state.data.sensor.aly ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Vibration"
                      color={
                        this.state.data.sensor.alvibration ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Door"
                      color={
                        this.state.data.sensor.aldoor ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Fire"
                      color={
                        this.state.data.sensor.alfire ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Fan"
                      color={
                        this.state.data.sensor.alfan ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Alarm Batt"
                      color={
                        this.state.data.sensor.albatt ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <DeviceFooter state={this.state} props={this.props} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
