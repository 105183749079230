import "./../../App.css";
import "./../Main.css";
import config from "../config.json";
import { accessCookie } from "../CookieManagement.js";
import { AESencrypt } from "../AESEncoder";

import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Tabs, Tab, IconButton, Select } from "@material-ui/core";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import ListIcon from "@material-ui/icons/List";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import GetAppIcon from "@material-ui/icons/GetApp";
import RefreshIcon from "@material-ui/icons/Refresh";
import CheckIcon from "@material-ui/icons/Check";

export async function controlDevice(serialid, input) {
  let response = await fetch(config.backend_url + "/be/controldevice", {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + accessCookie("bsetok"),
    },
    body: AESencrypt(
      JSON.stringify({
        serialid: serialid,
        input: input,
      }),
      config.aes_key
    ),
  });
  //
  if (response.status === 200) {
  } else {
  }
}
export async function downloadhistoricaldata(serialid, props) {
  let response = await fetch(config.backend_url + "/gethistoricaldata", {
    method: "POST",
    mode: "cors",
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + accessCookie("bsetok"),
    },
    body: AESencrypt(
      JSON.stringify({
        serialid: serialid,
      }),
      config.aes_key
    ),
  });
  if (response.status === 200) {
    let data = await response.json();
    const element = document.createElement("a");
    const file = new Blob([data], {
      type: "text/stream",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      props.userdata.serial[serialid].machinetype + "-" + serialid + ".csv";
    document.body.appendChild(element);
    element.click();
  } else {
  }
}

export function DeviceTab(props) {
  let state = props.state;
  let handleChange_select = props.handleChange_select;
  return (
    <div className="tab-card">
      <Tabs
        value={state.select}
        onChange={handleChange_select}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        aria-label="icon label tabs"
        style={{ height: "fit-content" }}
      >
        <Tab
          icon={<PersonalVideoIcon />}
          label="Monitoring"
          value="historical"
          style={{ fontSize: "12px" }}
        />
        <Tab
          icon={<ListIcon />}
          label="Control"
          value="control"
          style={{ fontSize: "12px" }}
          // disabled={!this_props.userdata.controlaccess.includes(state.serialid)}
        />
      </Tabs>
    </div>
  );
}
export function DeviceTitle(props) {
  let state = props.state;
  let this_props = props.props;
  let onSelectChange = props.onSelectChange;
  let onChartRefresh = props.onChartRefresh;
  return (
    <div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <IconButton
          style={{
            backgroundColor: "#e4e4e4",
            borderRadius: "32px",
            width: "32px",
            height: "32px",
          }}
          onClick={() => {
            window.location.href = "/dashboard";
            // "/dashboard?groupname=" +
            // this_props.userdata.serial[this_props.serialid].groupname;
          }}
        >
          <ArrowBackIosOutlinedIcon style={{ width: "20px", height: "20px" }} />
        </IconButton>

        <div
          className="text-title2"
          style={{
            margin: "0px",
            marginRight: "8px",
            marginLeft: "8px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {/* {"| "} */}
          {this_props.userdata.serial[state.serialid].serialname}
        </div>
        <IconButton
          style={{
            backgroundColor: "#e4e4e4",
            borderRadius: "32px",
            width: "32px",
            height: "32px",
            marginRight: "8px",
          }}
          onClick={() => {
            this_props.setState((prevstate) => ({
              ...prevstate,
              editdevicestate: true,
            }));
          }}
        >
          <EditIcon style={{ width: "20px", height: "20px" }} />
        </IconButton>
        <IconButton
          style={{
            backgroundColor: "#e4e4e4",
            borderRadius: "32px",
            width: "32px",
            height: "32px",
          }}
          onClick={() => {
            this_props.setState((prevstate) => ({
              ...prevstate,
              deletedevicestate: true,
            }));
          }}
        >
          <DeleteIcon style={{ width: "20px", height: "20px" }} />
        </IconButton>
      </div>

      {state.data.warning.iswarning && state.data.warning.warningdetail !== "" && (
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "16px",
            backgroundColor: "#F5B7B1",
            borderRadius: "5px",
            padding: "8px",
          }}
        >
          <div className="text-subtitle" style={{ margin: "0px" }}>
            <img
              src="/customicons/warningicon.png"
              alt=""
              style={{ width: "15px", marginRight: "5px" }}
            />
            Warning
          </div>
          <div className="text-option">{state.data.warning.warningdetail}</div>
        </div>
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "16px",
          marginBottom: "4px",
        }}
      >
        <Select native value={state.plotselection} onChange={onSelectChange}>
          <option value="Realtime">Realtime</option>
          <option value="t1hr">1hr</option>
          <option value="t1day">1day</option>
          <option value="t7day">7days</option>
          <option value="t1month">1month</option>
          <option value="t3month">3months</option>
        </Select>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <IconButton
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "32px",
              width: "32px",
              height: "32px",
              marginRight: "8px",
            }}
            onClick={() => {
              downloadhistoricaldata(state.serialid, this_props);
            }}
            disabled={
              !this_props.userdata.controlaccess.includes(state.serialid)
            }
          >
            <GetAppIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
          <IconButton
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "32px",
              width: "32px",
              height: "32px",
            }}
            onClick={onChartRefresh}
          >
            <RefreshIcon style={{ width: "20px", height: "20px" }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
export function DeviceFooter(props) {
  let state = props.state;
  let this_props = props.props;

  return (
    <div>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "16px",
        }}
      >
        <div className="text-subtitle" style={{ margin: "0px" }}>
          Machine
          {/* Notification */}
          <IconButton
            style={{
              backgroundColor: "#e4e4e4",
              borderRadius: "32px",
              width: "24px",
              height: "24px",
              marginLeft: "4px",
            }}
            onClick={() => {
              this_props.setState((prevstate) => ({
                ...prevstate,
                notimanagerstate: true,
              }));
            }}
          >
            {this_props.userdata.notification.includes(state.serialid) ? (
              <NotificationsActiveIcon
                style={{ width: "16px", height: "16px" }}
              />
            ) : (
              <NotificationsOffIcon style={{ width: "16px", height: "16px" }} />
            )}
          </IconButton>
        </div>
        <div className="text-option">
          {this_props.userdata.serial[state.serialid].machinetype}:{" "}
          {state.serialid}
        </div>
      </div>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "16px",
        }}
      >
        <div className="text-subtitle" style={{ margin: "0px" }}>
          <IconButton
            style={{
              backgroundColor: "#e4e4e4",
              borderRadius: "32px",
              width: "24px",
              height: "24px",
              marginRight: "4px",
            }}
            onClick={() => {
              window.open(
                "https://www.google.com/maps/search/" +
                  String(state.data.lat) +
                  "," +
                  String(state.data.lgn)
              );
            }}
          >
            <img
              src="/customicons/map-icon.png"
              style={{ width: "16px", height: "16px" }}
              alt=""
            />
          </IconButton>
          Location
        </div>
        <div className="text-option">Latitude: {state.data.lat}</div>
        <div className="text-option">Longtitude: {state.data.lgn}</div>
      </div>
      {this_props.userdata.serial[state.serialid].description !== "" && (
        <div
          style={{
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <div className="text-subtitle" style={{ margin: "0px" }}>
            Description
          </div>
          <div className="text-12 b-500 t-secondary a-left">
            {this_props.userdata.serial[state.serialid].description}
          </div>
        </div>
      )}
    </div>
  );
}
export function OnChartLoad(props) {
  let state = props.state;
  let currenttime = new Date(
    Date.now() + new Date().getTimezoneOffset() * 60 * 1000 + 7 * 60 * 60 * 1000
  );
  return (
    <div
      className="col"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "15px",
        marginBottom: "15px",
      }}
    >
      {(!state.isdataloading || state.plotselection === "Realtime") && (
        <Spinner
          animation="grow"
          variant="secondary"
          aria-hidden="true"
          style={{ marginRight: "5px" }}
          size="sm"
        />
      )}
      {state.plotselection === "Realtime" ? (
        <div>
          {/* {currenttime} */}
          {/* {state.data.ts} */}
          {currenttime - new Date(state.data.ts) <
          config.offline_time * 60 * 1000 ? (
            <div>
              Waiting Data From Device... If you waiting for a long time Please
              check your device
            </div>
          ) : (
            <div>Offline Please check your device</div>
          )}
        </div>
      ) : (
        <div>
          {!state.isdataloading ? (
            <div>Loading Data From Server...</div>
          ) : (
            <div>No Data</div>
          )}
        </div>
      )}
    </div>
  );
}

export function ControlComponent(props) {
  let state = props.state;
  let handleChange_refresh = props.handleChange_refresh;
  let this_props = props.props;
  return this_props.userdata.controlaccess.includes(state.serialid) ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "12px",
        marginBottom: "4px",
      }}
    >
      <IconButton className="button-circle" onClick={handleChange_refresh}>
        <RefreshIcon />
      </IconButton>
      <IconButton
        className="button-circle"
        style={{ background: "#53700d" }}
        onClick={() => {
          // this.controlDevice(this.state.serialid);
          controlDevice(state.serialid, state.control);
        }}
      >
        <CheckIcon style={{ color: "#ffffff" }} />
      </IconButton>
    </div>
  ) : (
    <div>
      <IconButton className="button-circle" onClick={handleChange_refresh}>
        <RefreshIcon />
      </IconButton>
    </div>
  );
}
export function MonitorLogs(serialid) {
  let socket = new WebSocket(
    config.backend_monitor_logs +
      "/" +
      serialid +
      "?auth=" +
      accessCookie("bsetok")
  );

  socket.onopen = function (e) {
    // alert("[open] Connection established");
    // alert("Sending to server");
    // socket.send("My name is John");
  };

  socket.onmessage = function (event) {
    // alert(`[message] Data received from server: ${event.data}`);
  };

  socket.onclose = function (event) {
    if (event.wasClean) {
      // alert(
      //   `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
      // );
    } else {
      // e.g. server process killed or network down
      // event.code is usually 1006 in this case
      // alert("[close] Connection died");
    }
  };

  socket.onerror = function (error) {
    // alert(`[error] ${error.message}`);
  };
}
