import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import { Navbar } from "./minicomponents";
import React, { useState, useEffect } from "react";
import { Link } from "@material-ui/core";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";


import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

// Backend
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";
import PublicIcon from "@material-ui/icons/Public";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
function Contactus() {
  const history = useHistory();
  let [userdata, setUserdata] = useState(null);
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + access_tok,
      },
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      setUserdata(data);
    } else {
      deleteCookie("bsetok");
      history.push("/");
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div>
      <div className="background">
        <div className="container page-block">
          <Navbar />

          <div className="device-card">
            {userdata && (
              <div className="data-card">
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <MapContainer
                        center={[13.695033, 100.40592]}
                        zoom={17}
                        scrollWheelZoom={false}
                        style={{ height: "60vh", width: "100wh" }}
                      >
                        <TileLayer
                          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        <Marker
                          position={[13.695033, 100.40592]}
                          icon={
                            new L.icon({
                              iconUrl:
                                "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
                              iconSize: [26, 41],
                              iconAnchor: [13, 41],
                            })
                          }
                        ></Marker>
                      </MapContainer>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      style={{
                        height: "60vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      <Card bg="light" style={{ marginBottom: "4px" }}>
                        <Card.Header>
                          <div className="text-title">
                            บริษัท บี.เอส.อี อีเล็คทรอนิค จำกัด
                          </div>
                        </Card.Header>
                        <Card.Body>
                          <p style={{ textAlign: "left" }}>
                            {"\t"} 29/135 ซอยกาญจนาภิเษก 005/1 ถนนวงแหวนรอบนอก
                            แขวงหลักสอง เขตบางแค กรุงเทพฯ 10160
                          </p>
                        </Card.Body>
                        <Card.Footer>
                          <div className="col-sm-6" style={{ width: "100%" }}>
                            <div>
                              <EmailIcon /> info@bseelectronic.com
                            </div>
                            <div>
                              <PublicIcon />{" "}
                              <Link href="https://www.bseelectronic.co.th">
                                www.bseelectronic.co.th
                              </Link>
                            </div>

                            <div>
                              <PhoneIcon /> 0-2803-6303-4, 0-2803-7636-7
                            </div>
                            <div>
                              <img
                                src="/customicons/fax-icon.png"
                                alt=""
                                style={{ width: "25px" }}
                              />
                              0-2803-7636
                            </div>
                          </div>
                        </Card.Footer>
                      </Card>
                      <Card bg="light" style={{ marginBottom: "4px" }}>
                        <Card.Body>
                          <Card.Title>Sale Contact</Card.Title>
                        </Card.Body>
                        <ListGroup>
                          <ListGroupItem>
                            <div>คุณพรชนก ศรีแก้ว</div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "200px" }}>
                                <PhoneIcon />{" "}
                                <a href="tel:+66-64-119-6006">064-119-6006</a>
                              </div>

                              <div style={{ width: "200px" }}>
                                <img
                                  src="/customicons/line-color-icon.png"
                                  alt=""
                                  style={{ width: "25px" }}
                                />{" "}
                                <a href="https://line.me/ti/p/~meawy_m">
                                  meawy_m
                                </a>
                              </div>
                            </div>
                          </ListGroupItem>
                          <ListGroupItem>
                            <div>คุณรัตนา ศรีแก้ว</div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "200px" }}>
                                <PhoneIcon />{" "}
                                <a href="tel:+66-81-243-7763">081-243-7763</a>
                              </div>

                              <div style={{ width: "200px" }}>
                                <img
                                  src="/customicons/line-color-icon.png"
                                  alt=""
                                  style={{ width: "25px" }}
                                />{" "}
                                <a href="https://line.me/ti/p/~rattbse">
                                  rattbse
                                </a>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      </Card>
                      <Card bg="light" style={{ marginBottom: "4px" }}>
                        <Card.Body>
                          <Card.Title>Technical Contact</Card.Title>
                        </Card.Body>
                        <ListGroup>
                          <ListGroupItem>
                            <div>คุณฤกษ์ดี โสภาสพ</div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "200px" }}>
                                <PhoneIcon />{" "}
                                <a href="tel:+66-93-453-5949">093-453-5949</a>
                              </div>

                              <div style={{ width: "200px" }}>
                                <img
                                  src="/customicons/line-color-icon.png"
                                  alt=""
                                  style={{ width: "25px" }}
                                />{" "}
                                <a href="https://line.me/ti/p/~0934535949">
                                  0934535949
                                </a>
                              </div>
                            </div>
                          </ListGroupItem>
                          <ListGroupItem>
                            <div>คุณณัฐพงศ์ ปิรัณฑะ</div>
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                flex: 1,
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "200px" }}>
                                <PhoneIcon />{" "}
                                <a href="tel:+66-92-984-2403">092-984-2403</a>
                              </div>

                              <div style={{ width: "200px" }}>
                                <img
                                  src="/customicons/line-color-icon.png"
                                  alt=""
                                  style={{ width: "25px" }}
                                />{" "}
                                <a href="https://line.me/ti/p/~rattbse">
                                  0929842403
                                </a>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
