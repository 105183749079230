import "./../../App.css";
import React from "react";
import "./../Main.css";

import {
  ControlComponent,
  DeviceTab,
  DeviceTitle,
  DeviceFooter,
  OnChartLoad,
  MonitorLogs,
} from "./Globalfn.js";
import {
  StatusPropCard,
  StatusSliderCard,
  StatusLightCardNew,
} from "../minicomponents";
import Chart from "react-apexcharts";

import { AESdecrypt } from "./../AESEncoder";

// backend
import config from "../config.json";

var strftime = require("strftime");
let mqtt = require("mqtt");

export default class DeviceBSACT2A1FAIOT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.userdata.serialposition[0],
      plotselection: "Realtime",
      plotlinedata: {
        Realtime: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },

        t1hr: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },
        t1day: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },
        t7day: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },
        t1month: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },
        t3month: {
          mnair1: [],
          mnair2: [],
          temp: [],
          humid: [],
          time: [],
        },
      },
      control: props.userdata.serialposition[0].input,
      select: "historical",
      serialid: props.serialid,
      isdataloading: false,
    };
  }
  async gettimeseriesdata(serialid) {
    let response = await fetch(
      config.backend_url +
        "/getdevicedata?serialid=" +
        serialid +
        "&query_input=mnair1,mnair2&query_sensor=temp,humid",
      {
        method: "GET",
        mode: "cors",
      }
    );
    if (response.status === 200) {
      let data = await response.json();
      Object.keys(data).map((i) => {
        this.state.plotlinedata[i] = data[i];
        return "";
      });
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        isdataloading: true,
      }));
    } else if (response.status === 404) {
      this.setState((prevstate) => ({
        ...prevstate,
        isdataloading: true,
      }));
    }
  }

  handleChange_select = (event, newValue) => {
    if (newValue === "control") {
      this.handleChange_refresh();
    }
    this.setState((prevstate) => ({ ...prevstate, select: newValue }));
  };
  async handleChange_control(name, newValue) {
    let dummy_data = this.state.control;
    dummy_data[name] = newValue;
    if (name === "returntemp") {
      if (
        dummy_data.returntemp + dummy_data.lowtemp + 1 > dummy_data.hightemp &&
        dummy_data.returntemp + dummy_data.lowtemp + 1 <= 50
      ) {
        dummy_data.hightemp = dummy_data.returntemp + dummy_data.lowtemp + 1;
      } else if (
        dummy_data.returntemp + dummy_data.lowtemp + 1 >
        dummy_data.hightemp
      ) {
        dummy_data.lowtemp = 50 - dummy_data.returntemp - 1;
      }
      if (dummy_data.hightemp - dummy_data.returntemp < dummy_data.lowtemp) {
        dummy_data.lowtemp = dummy_data.hightemp - dummy_data.returntemp;
      }
    } else if (name === "hightemp") {
      if (newValue - dummy_data.returntemp < dummy_data.lowtemp + 1) {
        dummy_data.lowtemp = dummy_data.hightemp - dummy_data.returntemp;
      }
    }
    if (this.props.userdata.controlaccess.includes(this.state.serialid)) {
      this.setState((prevstate) => ({ ...prevstate, control: dummy_data }));
    }
  }
  handleChange_refresh = () => {
    let default_data = { ...this.state.data.input };
    this.setState((prevstate) => ({ ...prevstate, control: default_data }));
  };
  tempClass = (temp) => {
    if (temp >= 80) {
      return "text-status-temp-3";
    } else if (temp >= 40) {
      return "text-status-temp-2";
    } else {
      return "text-status-temp-1";
    }
  };

  tempColor = (temp) => {
    if (temp >= 80) {
      return "#dc143c";
    } else if (temp >= 40) {
      return "#c27a37";
    } else {
      return "#53700d";
    }
  };

  componentDidMount() {
    const clientId =
      "reactuser_" +
      this.props.userdata.username +
      (Math.floor(Math.random() * 1000000) + 1).toString();
    const options = {
      keepalive: 60,
      username: config.mqtt_username,
      password: config.mqtt_pwd,
      clientId: clientId,
      protocolId: "MQTT",
      protocolVersion: 4,
      clean: true,
      reconnectPeriod: 1000,
      connectTimeout: 30 * 1000,
      will: {
        topic: "WillMsg",
        payload: "Connection Closed abnormally..!",
        qos: 0,
        retain: false,
      },
    };
    const client = mqtt.connect(config.mqtt_endpoint, options);
    client.on("error", (err) => {
      console.log("Connection error: ", err);
      client.end();
    });

    client.on("reconnect", () => {
      // console.log("Reconnecting...");
    });
    client.subscribe("updatedata/" + this.props.serialid + "/+", { qos: 0 });
    client.subscribe("updatedatas/" + this.props.serialid + "/+", { qos: 0 });
    client.on("message", (topic, message, packet) => {
      let data = message.toString();
      if (topic.split("/")[0] === "updatedatas") {
        data = AESdecrypt(data, config.device_aes_key);
      }
      data = JSON.parse(data);
      data.lat = parseFloat(data.lat);
      data.lgn = parseFloat(data.lgn);
      let currenttime = strftime(
        "%F %T",
        new Date(
          Date.now() +
            new Date().getTimezoneOffset() * 60 * 1000 +
            7 * 60 * 60 * 1000
        )
      );
      this.state.plotlinedata.Realtime.mnair1.push(data.input.mnair1);
      this.state.plotlinedata.Realtime.mnair2.push(data.input.mnair2);
      this.state.plotlinedata.Realtime.temp.push(data.sensor.temp);
      this.state.plotlinedata.Realtime.humid.push(data.sensor.humid);
      this.state.plotlinedata.Realtime.time.push(currenttime);
      while (
        new Date(currenttime) -
          new Date(this.state.plotlinedata.Realtime.time[0]) >=
        1000 * 60 * 3 // 3 min
      ) {
        this.state.plotlinedata.Realtime.mnair1.shift();
        this.state.plotlinedata.Realtime.mnair2.shift();
        this.state.plotlinedata.Realtime.temp.shift();
        this.state.plotlinedata.Realtime.humid.shift();
        this.state.plotlinedata.Realtime.time.shift();
      }
      this.setState((prevstate) => ({
        ...prevstate,
        plotlinedata: this.state.plotlinedata,
        data: data,
      }));
    });
    MonitorLogs(this.state.serialid);
    this.gettimeseriesdata(this.state.serialid);
  }

  render() {
    return (
      <div className="device-card">
        <DeviceTab
          state={this.state}
          handleChange_select={this.handleChange_select}
        />
        {this.state.select === "control" && (
          <div className="data-card">
            {!this.props.userdata.controlaccess.includes(
              this.state.serialid
            ) && (
              <div style={{ width: "100%" }}>
                You are not allowed to control this device.
              </div>
            )}
            <div className="row">
              <div className="col-sm-6">
                <StatusPropCard
                  title={this.state.control.mode ? "Auto" : "Manual"}
                  status={this.state.control.mode ? "Auto" : "Manual"}
                  icon={
                    <img
                      src="/customicons/mode.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="primary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("mode", !newValue ? 1 : 0)
                  }
                  value={!this.state.control.mode}
                />
                <StatusPropCard
                  title="Manual Air 1"
                  name="mnair1"
                  icon={
                    <img
                      src="/customicons/airicon.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="secondary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("mnair1", newValue ? 1 : 0)
                  }
                  value={this.state.control.mnair1}
                />
                <StatusPropCard
                  title="Manual Air 2"
                  name="mnair2"
                  icon={
                    <img
                      src="/customicons/airicon.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="secondary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("mnair2", newValue ? 1 : 0)
                  }
                  value={this.state.control.mnair2}
                />
                <StatusPropCard
                  title="Manual Fan"
                  name="mnfan"
                  icon={
                    <img
                      src="/customicons/fanicon.png"
                      style={{ width: "30px", height: "30px" }}
                      alt=""
                    />
                  }
                  mode="switch"
                  color="secondary"
                  onChange={(e, newValue) =>
                    this.handleChange_control("mnfan", newValue ? 1 : 0)
                  }
                  value={this.state.control.mnfan}
                />
              </div>
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set High Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("hightemp", newValue)
                      }
                      value={this.state.control.hightemp}
                      step={1}
                      min={
                        this.state.control.returntemp +
                        this.state.control.lowtemp +
                        1
                      }
                      max={50}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Set Low Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("lowtemp", newValue)
                      }
                      value={this.state.control.lowtemp}
                      step={1}
                      min={0}
                      max={
                        this.state.control.hightemp -
                        this.state.control.returntemp -
                        1
                      }
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Time Air"
                      onChange={(e, newValue) =>
                        this.handleChange_control("timeair", newValue)
                      }
                      value={this.state.control.timeair}
                      step={1}
                      min={1}
                      max={24}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Return Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("returntemp", newValue)
                      }
                      value={this.state.control.returntemp}
                      step={1}
                      min={1}
                      max={5}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Over High Temp"
                      onChange={(e, newValue) =>
                        this.handleChange_control("overhightemp", newValue)
                      }
                      value={this.state.control.overhightemp}
                      step={1}
                      min={5}
                      max={10}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Overlap Time"
                      onChange={(e, newValue) =>
                        this.handleChange_control("overlabtime", newValue)
                      }
                      value={this.state.control.overlabtime}
                      step={1}
                      min={5}
                      max={15}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="High Humidity"
                      onChange={(e, newValue) =>
                        this.handleChange_control("highhumid", newValue)
                      }
                      value={this.state.control.highhumid}
                      step={1}
                      min={0}
                      max={99}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <StatusSliderCard
                      title="Return Humidity"
                      onChange={(e, newValue) =>
                        this.handleChange_control("returnhumid", newValue)
                      }
                      value={this.state.control.returnhumid}
                      step={1}
                      min={1}
                      max={30}
                      clsName="text-status-step"
                      color="#c27a37"
                      decscale={2}
                    />
                  </div>
                </div>
                <ControlComponent
                  state={this.state}
                  handleChange_refresh={this.handleChange_refresh}
                  props={this.props}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.select === "historical" && (
          <div className="data-card">
            <DeviceTitle
              state={this.state}
              props={this.props}
              onSelectChange={(e) => {
                this.setState((prevstate) => ({
                  ...prevstate,
                  plotselection: e.target.value,
                }));
              }}
              onChartRefresh={() => {
                this.gettimeseriesdata(this.state.serialid);
              }}
            />
            {/* Linechart HERE */}
            {this.state.plotlinedata[this.state.plotselection].time.length !==
            0 ? (
              <div
                //className="main chart-wrapper"
                style={{
                  //overflowX: "scroll",
                  //alignItems: "center",
                  width: "100%",
                  height: "650px",
                  display: "inline-block",
                  marginBottom: "20px",
                }}
              >
                <div id="chartwrapper">
                  <div
                    id="chart-temp1"
                    style={{
                      //overflowX: "scroll",
                      //alignItems: "center",
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].temp.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Temp",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          //group: "devicechart",
                          type: "line",
                          //staked: false,
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#008FFB"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-temp2"
                    style={{
                      width: "100%",
                      height: "200px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].humid.map((e, index) => {
                            return {
                              x: this.state.plotlinedata[
                                this.state.plotselection
                              ].time[index],
                              y: e,
                            };
                          }),
                          name: "Humidity",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "temp",
                          type: "line",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#ffa500"],
                        stroke: {
                          curve: ["straight"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            labels: {
                              minWidth: 40,
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          showAlways: true,

                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="line"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div
                    id="chart-manual-air1"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].mnair1.map((e, index) => {
                            if (e) {
                              return {
                                x: this.state.plotlinedata[
                                  this.state.plotselection
                                ].time[index],
                                y: 1,
                              };
                            } else {
                              return {
                                x: this.state.plotlinedata[
                                  this.state.plotselection
                                ].time[index],
                                y: 0,
                              };
                            }
                          }),
                          name: "Status Air1",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "manual-air1",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#00E396"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                    />
                  </div>

                  <div
                    id="chart-manual-air2"
                    style={{
                      width: "100%",
                      height: "125px",
                      display: "inline-block",
                    }}
                  >
                    <Chart
                      series={[
                        {
                          data: this.state.plotlinedata[
                            this.state.plotselection
                          ].mnair2.map((e, index) => {
                            if (e) {
                              return {
                                x: this.state.plotlinedata[
                                  this.state.plotselection
                                ].time[index],
                                y: 1,
                              };
                            } else {
                              return {
                                x: this.state.plotlinedata[
                                  this.state.plotselection
                                ].time[index],
                                y: 0,
                              };
                            }
                          }),
                          name: "Status Air2",
                        },
                      ]}
                      options={{
                        chart: {
                          id: "manual-air2",
                          type: "area",
                          animations: {
                            enabled: false,
                          },
                          zoom: {
                            enabled: false,
                          },
                          toolbar: {
                            show: false,
                          },
                          height: "100%",
                        },
                        colors: ["#f3ea5f"],
                        stroke: {
                          curve: ["stepline"],
                          width: 1,
                        },
                        dataLabels: {
                          enabled: false,
                        },
                        xaxis: {
                          type: "datetime",
                          categories:
                            this.state.plotlinedata[this.state.plotselection]
                              .time,
                          datetimeUTC: false,
                          labels: {
                            datetimeUTC: false,
                          },
                          datetimeFormatter: {
                            year: "yyyy",
                            month: "dd MMM yyyy",
                            day: "dd MMM yyyy HH:mm:ss",
                            hour: "HH:mm:ss",
                          },
                        },
                        yaxis: [
                          {
                            min: 0,
                            max: 1,
                            tickAmount: 1,
                            forceNiceScale: true,
                            showAlways: true,

                            labels: {
                              minWidth: 40,
                              formatter: (value) => {
                                if (value === 1) {
                                  return "On";
                                } else if (value === 0) {
                                  return "Off";
                                } else {
                                  return "";
                                }
                              },
                            },
                          },
                        ],
                        legend: {
                          show: true,
                          showForSingleSeries: true,
                          position: "top",
                          horizontalAlign: "left",
                        },
                        tooltip: {
                          enabled: true,
                          shared: true,
                          x: {
                            format: "dd MMMM yyyy HH:mm:ss",
                          },
                        },
                      }}
                      type="area"
                      width="100%"
                      height="100%"
                      //key={1}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <OnChartLoad state={this.state} />
            )}
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/TempIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-ref">
                          {this.state.data.sensor.temp}°c
                        </div>
                      )}
                      <div className="text-status-status">Temperature</div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="status-show-block">
                      <img
                        style={{ height: "24px" }}
                        src="/customicons/HumidIcon.png"
                        alt=""
                      />
                      {this.state.data && (
                        <div className="text-status-step">
                          {this.state.data.sensor.humid}
                        </div>
                      )}
                      <div className="text-status-status">Humidity</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {this.state.data && (
                      <div className="status-show-block">
                        <div className="text-status-out">
                          {this.state.data.sensor.timeair}
                        </div>
                        <div className="text-status-status">TimeAir</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="status-block-new">
                  <div className="text-subtitle" style={{ margin: "0px" }}>
                    Status
                  </div>
                  <div
                    className="row"
                    style={{ flex: 1, justifyContent: "space-between" }}
                  >
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title={!this.state.data.input.mode ? "Manual" : "Auto"}
                      color={
                        !this.state.data.input.mode ? (
                          <div className="status-light-orange"></div>
                        ) : (
                          <div className="status-light-green"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Air1"
                      color={
                        this.state.data.sensor.air1 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Air2"
                      color={
                        this.state.data.sensor.air2 ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Fan"
                      color={
                        this.state.data.sensor.fan ? (
                          <div className="status-light-green"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="status-block-new">
                  <div className="text-subtitle" style={{ margin: "0px" }}>
                    Status Alarm
                  </div>
                  <div
                    className="row"
                    style={{ flex: 1, justifyContent: "space-between" }}
                  >
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Air1 Fail"
                      color={
                        this.state.data.sensor.alair1 ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Air2 Fail"
                      color={
                        this.state.data.sensor.alair2 ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Fan Fail"
                      color={
                        this.state.data.sensor.alfan ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="High Temp"
                      color={
                        this.state.data.sensor.hightemp ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Low Temp"
                      color={
                        this.state.data.sensor.lowtemp ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="High Humidity"
                      color={
                        this.state.data.sensor.alhumid ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Sensor Error"
                      color={
                        this.state.data.sensor.alsensor ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                    <StatusLightCardNew
                      style={{ flex: 1 }}
                      title="Fire Alarm"
                      color={
                        this.state.data.sensor.firealarm ? (
                          <div className="status-light-red"></div>
                        ) : (
                          <div className="status-light-gray"></div>
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <DeviceFooter state={this.state} props={this.props} />
            </div>
          </div>
        )}
      </div>
    );
  }
}
