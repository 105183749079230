import "./../App.css";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Main.css";
import { TextField } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";
function ForgotPass() {
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [issent, setIssent] = useState(false);
  const [iswaiting, setIswaiting] = useState(false);
  async function sentResetRequest() {
    if (username !== "") {
      await setIswaiting(true);
      let response = await fetch(
        config.backend_url + "/be/forgotpassword?username=" + username,
        {
          method: "GET",
          mode: "cors",
        }
      );
      if (response.status === 200) {
        setIssent(true);
      } else {
        document.getElementById("warning").innerHTML = "Please Try Again!!!";
      }
    }
    //
  }
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + access_tok,
      },
    });
    //
    if (response.status === 200) {
      history.push("/dashboard");
    } else {
      deleteCookie("bsetok");
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div className="background">
      <div className="container home-block">
        <div className="home-card">
          <div className="text-title">Forgot Password</div>
          {!issent ? (
            <div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <AccountCircle />
                  <TextField
                    label="Username"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                  />
                </div>
              </div>
              <div className="space-text">
                <div
                  className="text-option"
                  id="warning"
                  style={{ color: "red" }}
                ></div>
              </div>
              <div
                className="button"
                onClick={sentResetRequest}
                style={{ cursor: "pointer" }}
              >
                {!iswaiting ? (
                  <div className="button-text-white">Continue</div>
                ) : (
                  <div className="button-text-white">
                    <Spinner animation="border" variant="light" size="sm" />{" "}
                    Waiting...
                  </div>
                )}
              </div>
              <div
                className="button-border"
                onClick={() => history.replace("/")}
                style={{ cursor: "pointer" }}
              >
                <div className="button-text">Back</div>
              </div>
            </div>
          ) : (
            <div>
              <div className="space-text"></div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  We sent Reset Password Link to your Email. Please check it!!!
                </div>
              </div>
              <div className="space-text"></div>
              <div
                className="button"
                onClick={() => history.replace("/")}
                style={{ cursor: "pointer" }}
              >
                <div className="button-text-white">OK</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;
