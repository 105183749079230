import "./../../App.css";
import "../Main.css";
import { Switch, Button } from "@material-ui/core";

function StatusPropCard(props) {
  const { title, status, icon, mode, onChange, value, color, custom } =
    props;

  let screen;
  if (mode === "switch") {
    screen = <Switch checked={value} onChange={onChange} color={color} />;
  } else if (mode === "button") {
    screen = (
      <Button
        variant="outlined"
        color="secondary"
        style={{ fontSize: "12px" }}
        onClick={onChange}
      >
        Reset
      </Button>
    );
  } else if (mode === "custom") {
    screen = custom;
  }
  return (
    <div className="status-block">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        {icon}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: "8px",
          }}
        >
          <div className="text-status-title">{title}</div>
          <div className="text-status-status">
            {status != null ? "Status: " + status : undefined}
          </div>
        </div>
      </div>
      {screen}
    </div>
  );
}

export default StatusPropCard;
