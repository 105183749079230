import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import {
  TextField,
  Tabs,
  Tab,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
//import EditIcon from "@material-ui/icons/Edit";
import { Navbar } from "./minicomponents";
import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Backend
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";

import { AESencrypt } from "./AESEncoder";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NotificationsIcon from "@material-ui/icons/Notifications";
function Settings() {
  const history = useHistory();
  let [state, setState] = useState({
    changepasswordstate: false,
    changeemailstate: false,
    newemail: "",
    newemailsuccess: 0,
    oldpassword: "",
    newpassword: "",
    cnfrmpassword: "",
    reportstatus: false,
    apitokenstatus: false,
    apitoken: "",
    linenotifystatus: false,
    linenotifytoken: "",
    notifytype: [],
    notifytypestatus: false,
  });
  let [reportpayload, setReportpayload] = useState({
    name: "",
    email: "",
    tel: "",
    title: "",
    description: "",
  });
  let [validstate, setValidstate] = useState({
    newpassword: true,
    cnfrmpassword: true,
    newemail: true,
  });
  let [userdata, setUserdata] = useState(null);
  let [tabselection, setTabseclection] = useState("accountsettings");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "newpassword") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpassword") {
      if (value !== state.newpassword || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "newemail") {
      if (
        value === "" ||
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      let new_arr = state.notifytype;
      new_arr.push(e.target.name);
      setState((prevState) => ({
        ...prevState,
        notifytype: new_arr,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        notifytype: state.notifytype.filter(function (ee) {
          return ee !== e.target.name;
        }),
      }));
    }
  };
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_tok,
      },
      body: "",
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      setUserdata(data);
      setState((prevstate) => ({
        ...prevstate,
        linenotifytoken: data.linenotifytoken,
        notifytype: data.notifytype,
      }));
    } else {
      deleteCookie("bsetok");
      history.push("/");
    }
  }
  async function changeNewpassword() {
    if (
      validstate.newpassword &&
      validstate.cnfrmpassword &&
      state.newpassword.length != 0
    ) {
      let response = await fetch(config.backend_url + "/be/changepassword", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "text/plain",
          Authorization: "Bearer " + accessCookie("bsetok"),
        },
        body: AESencrypt(
          JSON.stringify({
            oldpwd: state.oldpassword,
            userpwd: state.newpassword,
          }),
          config.aes_key
        ),
      });

      if (response.status === 200) {
        setState((prevstate) => ({
          ...prevstate,
          changepasswordstate: false,
          oldpassword: "",
          newpassword: "",
          cnfrmpassword: "",
        }));
        setValidstate((prevstate) => ({
          ...prevstate,
          newpassword: true,
          cnfrmpassword: true,
        }));
        document.getElementById("changepasswordwarning").innerHTML = "";
      } else {
        setState((prevstate) => ({
          ...prevstate,
          changepasswordstate: true,
          oldpassword: "",
          newpassword: "",
          cnfrmpassword: "",
        }));
        setValidstate((prevstate) => ({
          ...prevstate,
          newpassword: true,
          cnfrmpassword: true,
        }));
        document.getElementById("changepasswordwarning").innerHTML =
          "Please Try Again";
      }
    } else if (state.newpassword.length === 0) {
      setValidstate((prevState) => ({
        ...prevState,
        newpassword: false,
      }));
    }
  }

  async function changeEmail() {
    if (validstate.newemail && state.newemail.length != 0) {
      let response = await fetch(
        config.backend_url + "/be/changeemailrequest",
        {
          method: "POST",
          mode: "cors",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessCookie("bsetok"),
          },
          body: JSON.stringify({
            new_email: state.newemail,
          }),
        }
      );

      if (response.status === 200) {
        setState((prevstate) => ({
          ...prevstate,
          //changeemailstate: false,
          newemailsuccess: 1,
          //newemail: "",
        }));
        setValidstate((prevstate) => ({
          ...prevstate,
          newemail: true,
        }));
      } else {
        setState((prevstate) => ({
          ...prevstate,
          changeemailstate: true,
          newemail: "",
        }));
        setValidstate((prevstate) => ({
          ...prevstate,
          newemail: true,
        }));
        document.getElementById("changeemailwarning").innerHTML =
          "Please Try Again";
      }
    } else if (state.newemail.length === 0) {
      setValidstate((prevState) => ({
        ...prevState,
        newemail: false,
      }));
    }
  }

  async function sendreport() {
    let response = await fetch(config.backend_url + "/be/report", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify(reportpayload),
    });
    if (response.status === 200) {
      setReportpayload({
        name: "",
        email: "",
        tel: "",
        title: "",
        description: "",
      });
      setState((prevState) => ({
        ...prevState,
        reportstatus: true,
      }));
    }
  }
  async function update_line_notify_token() {
    let response = await fetch(config.backend_url + "/be/addlinenotifytoken", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify({ linenotifytoken: state.linenotifytoken }),
    });
    if (response.status === 200) {
      setUserdata((prevState) => ({
        ...prevState,
        linenotifytoken: state.linenotifytoken,
      }));
      setState((prevState) => ({
        ...prevState,
        linenotifytoken: state.linenotifytoken,
        linenotifystatus: false,
      }));
    }
  }
  async function update_notify_channel() {
    let response = await fetch(config.backend_url + "/be/setnotifytype", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify(state.notifytype),
    });
    if (response.status === 200) {
      setUserdata((prevState) => ({
        ...prevState,
        notifytype: state.notifytype,
      }));
      setState((prevState) => ({
        ...prevState,
        notifytype: state.notifytype,
        notifytypestatus: false,
      }));
    }
  }
  useEffect(() => {
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="background">
      <Modal show={state.changepasswordstate} backdrop="static" centered>
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          <div className="popup-card2">
            <div className="text-title">Change Password</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Old Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="oldpassword"
                  value={state.oldpassword}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  error={!validstate.newpassword}
                  label="New Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="newpassword"
                  value={state.newpassword}
                  onChange={handleChange}
                  helperText={
                    !validstate.newpassword &&
                    "Password must be included 6-14 character."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  error={!validstate.cnfrmpassword}
                  label="Confirm New Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="cnfrmpassword"
                  value={state.cnfrmpassword}
                  onChange={handleChange}
                  helperText={
                    !validstate.cnfrmpassword && "Password is not match."
                  }
                />
              </div>
            </div>

            <div className="space-text">
              <div
                className="text-option"
                id="changepasswordwarning"
                style={{ color: "red" }}
              ></div>
            </div>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={changeNewpassword}
            >
              Change Password
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  changepasswordstate: false,
                  oldpassword: "",
                  newpassword: "",
                  cnfrmpassword: "",
                }));
                setValidstate((prevstate) => ({
                  ...prevstate,
                  newpassword: true,
                  cnfrmpassword: true,
                }));
                document.getElementById("changepasswordwarning").innerHTML = "";
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal show={state.changeemailstate} backdrop="static" centered>
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          {state.newemailsuccess === 0 ? (
            <div className="popup-card2">
              <div className="text-title">Change Email</div>

              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    error={!validstate.newemail}
                    label="New Email Address"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="newemail"
                    value={state.newemail}
                    onChange={handleChange}
                    helperText={!validstate.newemail && "Incorrect Email."}
                  />
                </div>
              </div>

              <div className="space-text">
                <div
                  className="text-option"
                  id="changeemailwarning"
                  style={{ color: "red" }}
                ></div>
              </div>
              <Button
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                style={{ width: "100%", marginBottom: "8px" }}
                onClick={changeEmail}
              >
                Change Email
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="default"
                style={{ width: "100%" }}
                onClick={() => {
                  setState((prevstate) => ({
                    ...prevstate,
                    changeemailstate: false,
                    newemail: "",
                  }));
                  setValidstate((prevstate) => ({
                    ...prevstate,
                    newemail: true,
                  }));
                  document.getElementById("changeemailwarning").innerHTML = "";
                }}
              >
                Close
              </Button>
            </div>
          ) : (
            <div className="popup-card2">
              <CheckCircleIcon
                style={{
                  fontSize: "64px",
                  width: "100%",
                  margin: "auto",
                  color: "#49AA19",
                }}
              />
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "600",
                }}
              >
                Your request has been sent
              </div>
              <div
                style={{
                  marginTop: "16px",
                  marginBottom: "16px",
                  padding: "16px",
                  backgroundColor: "#F8F8FA",
                  textAlign: "center",
                  fontSize: "16px",
                }}
              >
                We have sent you a verification link to{" "}
                <div style={{ fontWeight: "600" }}>{state.newemail}.</div>{" "}
                Please verify within 15 minutes.
              </div>
              <Button
                variant="outlined"
                size="large"
                disableElevation
                style={{ width: "100%", marginBottom: "8px" }}
                onClick={() => {
                  setState((prevstate) => ({
                    ...prevstate,
                    changeemailstate: false,
                    newemail: "",
                    newemailsuccess: 0,
                  }));
                  setValidstate((prevstate) => ({
                    ...prevstate,
                    newemail: true,
                  }));
                }}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </Modal>
      <Dialog
        open={state.reportstatus}
        keepMounted
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            reportstatus: false,
          }));
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Your report was submitted"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Thanks for your report. We will contact you back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                reportstatus: false,
              }));
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={state.apitokenstatus}
        // open
        keepMounted
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            apitokenstatus: false,
            apitoken: "",
          }));
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Your token has been generated!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              value={state.apitoken}
              multiline
              rowsMax={Infinity}
              style={{ width: "100%" }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      navigator.clipboard.writeText(state.apitoken);
                    }}
                  >
                    <FileCopyIcon />
                  </IconButton>
                ),
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                apitokenstatus: false,
                apitoken: "",
              }));
            }}
            color="primary"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Modal show={state.linenotifystatus} backdrop="static" centered>
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          <div className="popup-card2">
            <div className="text-title">Change LINE Notify Token</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Token"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="linenotifytoken"
                  value={state.linenotifytoken}
                  onChange={handleChange}
                />
              </div>
            </div>

            <Button
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={update_line_notify_token}
            >
              Change Token
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  linenotifystatus: false,
                  linenotifytoken: userdata.linenotifytoken,
                }));
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <Modal show={state.notifytypestatus} backdrop="static" centered>
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          <div className="popup-card2">
            <div className="text-title">Change Notify Channel</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginBottom: "8px",
              }}
            >
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.notifytype.includes("line")}
                      onChange={handleChangeCheckbox}
                      name="line"
                      color="primary"
                    />
                  }
                  label="Line"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.notifytype.includes("email")}
                      onChange={handleChangeCheckbox}
                      name="email"
                      color="primary"
                    />
                  }
                  label="Email"
                />
              </FormControl>

              {/* {JSON.stringify(state.notifytype)} */}
            </div>

            <Button
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={update_notify_channel}
            >
              OK
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  notifytypestatus: false,
                  notifytype: userdata.notifytype,
                }));
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <div className="container page-block">
        <Navbar />
        <div className="device-card">
          <div className="tab-card">
            <Tabs
              value={tabselection}
              onChange={(e, v) => {
                setTabseclection(v);
                if (v === "reportproblem") {
                  setReportpayload({
                    name: "",
                    email: "",
                    tel: "",
                    title: "",
                    description: "",
                  });
                }
              }}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="icon label tabs"
              style={{ height: "fit-content" }}
            >
              <Tab
                icon={<PersonIcon />}
                label="Account Settings"
                value="accountsettings"
                style={{ fontSize: "12px" }}
              />
              <Tab
                icon={<ReportProblemIcon />}
                label="Report Problem"
                value="reportproblem"
                style={{ fontSize: "12px" }}
              />
            </Tabs>
          </div>
          {userdata && tabselection === "accountsettings" && (
            <div className="data-card">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    className="text-title"
                    style={{ alignItems: "flex-start" }}
                  >
                    Account Information
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <AccountCircleIcon />
                    <div className="text-16 b-600 t-primary">USERNAME</div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {userdata.username}
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <MailOutlineIcon />
                    <div className="text-16 b-600 t-primary">EMAIL</div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {userdata.useremail}
                      <EditIcon
                        style={{
                          fontSize: "16px",
                          marginLeft: "8px",
                          cursor: "pointer",
                          color: "#008CBA",
                        }}
                        onClick={() => {
                          setState((prevstate) => ({
                            ...prevstate,
                            changeemailstate: true,
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <AssignmentIndIcon />
                    <div className="text-16 b-600 t-primary">NAME</div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {userdata.userfirstname + " " + userdata.userlastname}
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <LockOpenIcon />
                    <div className="text-16 b-600 t-primary">PASSWORD</div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        color: "#008CBA",
                      }}
                      onClick={() => {
                        setState((prevstate) => ({
                          ...prevstate,
                          changepasswordstate: true,
                        }));
                      }}
                    >
                      Reset Password
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <img
                      src="/customicons/lineicon.png"
                      alt=""
                      style={{ width: "25px" }}
                    />
                    <div className="text-16 b-600 t-primary">LINE Notify</div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        color: "#008CBA",
                      }}
                      onClick={() => {
                        setState((prevstate) => ({
                          ...prevstate,
                          linenotifystatus: true,
                        }));
                      }}
                    >
                      LINE Notify Token
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className="white-block"
                    style={{
                      margin: "0px 0px 16px 0px",
                      padding: "16px 16px 16px 16px",
                      borderImage:
                        "linear-gradient(134.32deg, #ECE9EA 25.3%, #E5E7EB 100%) 1",
                      borderStyle: "solid",
                      borderWidth: "5px",
                    }}
                  >
                    <NotificationsIcon />
                    <div className="text-16 b-600 t-primary">
                      Notify Channel
                    </div>
                    <div
                      className="text-14 b-400 t-primary"
                      style={{
                        marginTop: "8px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                        color: "#008CBA",
                      }}
                      onClick={() => {
                        setState((prevstate) => ({
                          ...prevstate,
                          notifytypestatus: true,
                        }));
                      }}
                    >
                      Set Notify Channel
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {tabselection === "reportproblem" && (
            <div className="data-card">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "400px",
                    width: "80%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="textfield">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        label="Name"
                        value={reportpayload.name}
                        // className="textfield"
                        style={{ marginLeft: "8px", width: "100%" }}
                        onChange={(e) => {
                          setReportpayload((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="textfield">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        label="Email"
                        value={reportpayload.email}
                        // className="textfield"
                        style={{ marginLeft: "8px", width: "100%" }}
                        onChange={(e) => {
                          setReportpayload((prevState) => ({
                            ...prevState,
                            email: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="textfield">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        label="Tel"
                        value={reportpayload.tel}
                        // className="textfield"
                        style={{ marginLeft: "8px", width: "100%" }}
                        onChange={(e) => {
                          setReportpayload((prevState) => ({
                            ...prevState,
                            tel: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="textfield">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        label="Title"
                        // className="textfield"
                        value={reportpayload.title}
                        style={{ marginLeft: "8px", width: "100%" }}
                        onChange={(e) => {
                          setReportpayload((prevState) => ({
                            ...prevState,
                            title: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="textfield-multiple-line">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // alignItems: "flex-end",
                      }}
                    >
                      <TextField
                        label="Description"
                        value={reportpayload.description}
                        // className="textfield-multiple-line"
                        style={{ marginLeft: "8px", width: "100%" }}
                        multiline
                        rowsMax={Infinity}
                        onChange={(e) => {
                          setReportpayload((prevState) => ({
                            ...prevState,
                            description: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disableElevation
                    style={{ width: "100%", marginBottom: "8px" }}
                    onClick={sendreport}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
