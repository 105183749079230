import "./../../App.css";
import "../Main.css";
import { Slider, IconButton } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
function StatusSliderCard(props) {
  const { title, onChange, value, step, min, max, clsName, color, decscale } =
    props;

  const MySlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#fff",
          border: "2px solid currentColor",
          marginTop: -8,
          marginLeft: -12,
          "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
          },
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)",
        },
        track: {
          height: 8,
          borderRadius: 4,
        },
        rail: {
          height: 8,
          borderRadius: 4,
        },
      },
    },
  });
  function roundfloat(x, num = 0) {
    if (num === 0) {
      return Math.round(x);
    }
    return Math.round(x * Math.pow(10, num)) / Math.pow(10, num);
  }
  return (
    <div className="status-show-block">
      <div className="row" style={{ width: "100%" }}>
        <div className="col" style={{ flex: 1 }}>
          <IconButton
            disabled={value === min}
            style={{
              backgroundColor: "#e4e4e4",
              borderRadius: "16px",
              width: "16px",
              height: "16px",
              //marginRight: "8px",
            }}
            onClick={() => {
              onChange("", Math.max(roundfloat(value - step, decscale), min));
            }}
          >
            <RemoveIcon />
          </IconButton>
        </div>
        <div className="col" style={{ width: "100%", flex: 98 }}>
          <ThemeProvider theme={MySlider}>
            <Slider
              aria-labelledby="discrete-slider-small-steps"
              step={step}
              min={min}
              max={max}
              valueLabelDisplay="auto"
              onChange={onChange}
              value={value}
              style={{ color: color }}
            />
          </ThemeProvider>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <IconButton
            disabled={value === max}
            style={{
              backgroundColor: "#e4e4e4",
              borderRadius: "16px",
              width: "16px",
              height: "16px",
              //marginRight: "8px",
            }}
            onClick={() => {
              onChange("", Math.min(roundfloat(value + step, decscale), max));
            }}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div className={clsName} style={{ color: color }}>
        {value}
      </div>
      <div className="text-status-status">{title}</div>
    </div>
  );
}

export default StatusSliderCard;
