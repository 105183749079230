import "./../App.css";
import { useHistory, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Main.css";
import { TextField } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
// Backend
import config from "./config.json";
import { AESencrypt } from "./AESEncoder";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function ResetPass() {
  const history = useHistory();
  let query = useQuery();
  let [state, setState] = useState({ userpwd: "", cnfrmpwd: "" });
  let [validstate, setValidstate] = useState({
    userpwd: true,
    cnfrmpwd: true,
  });
  const [issuccess, setIssuccess] = useState(false);
  async function getuser() {
    let Auth = await query.get("Auth");
    if (Auth) {
      let response = await fetch(
        config.backend_url + "/be/isvalidAuth?Auth=" + Auth,
        {
          method: "GET",
          mode: "cors",
        }
      );
      //
      if (response.status === 200) {
      } else {
        history.replace("/");
      }
    } else {
      history.replace("/");
    }
  }
  async function resetpwd() {
    await Object.keys(state).map(check_valid);
    if (
      Object.values(validstate).reduce((a, b) => a + b, 0) === 2 &&
      state.userpwd.length !== 0 &&
      state.cnfrmpwd.length !== 0
    ) {
      let response = await fetch(config.backend_url + "/be/resetpassword", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: AESencrypt(
          JSON.stringify({
            Auth: query.get("Auth"),
            userpwd: state.userpwd,
          }),
          config.aes_key
        ),
      });
      //
      if (response.status === 200) {
        setIssuccess(true);
      } else {
        //Error
        document.getElementById("warning").innerHTML =
          "Error, Please try again later...";
      }
    }
  }
  async function check_valid(name) {
    let value = state[name];
    if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        return false;
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        return true;
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        return false;
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        return true;
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };
  useEffect(() => {
    getuser();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  //const location = useLocation();
  return (
    <div className="background">
      <div className="container home-block">
        {!issuccess ? (
          <div className="home-card">
            <div className="text-title">Reset Password</div>
            <div className="text-subtitle"></div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <LockIcon />
                <TextField
                  error={!validstate.userpwd}
                  label="New Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="userpwd"
                  value={state.userpwd}
                  onChange={handleChange}
                  helperText={
                    !validstate.userpwd &&
                    "Password must be included 6-14 character."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <EnhancedEncryptionIcon />
                <TextField
                  error={!validstate.cnfrmpwd}
                  label="Confirm New Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="cnfrmpwd"
                  value={state.cnfrmpwd}
                  onChange={handleChange}
                  helperText={!validstate.cnfrmpwd && "Password is not match."}
                />
              </div>
            </div>
            <div className="space-text">
              <div id="warning" style={{ color: "red" }}></div>
            </div>
            <div
              className="button"
              onClick={resetpwd}
              style={{ cursor: "pointer" }}
            >
              <div className="button-text-white">Reset Password</div>
            </div>
          </div>
        ) : (
          <div className="home-card">
            <div className="text-title">Reset Password Success</div>
            <div className="text-subtitle"></div>
            <div className="space-text">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                Reset password success.
              </div>
            </div>

            <div className="space-text"></div>
            <div
              className="button"
              onClick={() => history.replace("/")}
              style={{ cursor: "pointer" }}
            >
              <div className="button-text-white">OK</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPass;
