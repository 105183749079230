import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import { TextField } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import CachedIcon from "@material-ui/icons/Cached";
import React, { useState, useEffect } from "react";
// Backend
import config from "./config.json";
import {
  createCookie,
  deleteCookie,
  accessCookie,
} from "./CookieManagement.js";
import { AESencrypt } from "./AESEncoder";

function Home() {
  const history = useHistory();
  // Backend
  let [state, setState] = useState({
    username: "",
    userpwd: "",
    captcha: "",
    captchatoken: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  async function getCaptcha() {
    let response = await fetch(config.backend_url + "/getcaptcha", {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      setState((prevstate) => ({
        ...prevstate,
        captchatoken: data.captcha,
        captcha: "",
      }));
      if (document.getElementById("captcha")) {
        document.getElementById("captcha").innerHTML =
          '<img src="' +
          config.backend_url +
          "/captchaimg/" +
          data.captcha +
          '" width="80%" alt="">';
      }
    }
  }

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + access_tok,
      },
    });
    //let data = await response.json();
    if (response.status !== 200) {
      deleteCookie("bsetok");
    } else {
      history.push("/home");
      //window.location.href = "/dashboard";
    }
  }

  async function LoginSession() {
    let response = await fetch(config.backend_url + "/be/login", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
      },
      body: AESencrypt(JSON.stringify(state), config.aes_key),
    });
    let data = await response.json();
    if (response.status === 200) {
      createCookie("bsetok", data.access_token, 4);
      history.push("/home");
      //window.location.href = "/dashboard";
    } else {
      //alert("Please Try Again");
      getCaptcha();
      if (document.getElementById("warning")) {
        document.getElementById("warning").innerHTML = data.detail;
      }
    }
  }

  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    }
    getCaptcha();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div className="background">
      <div className="container home-block">
        <div className="home-card">
          <img src="/logo_bse.png" style={{ width: "30%" }} alt="" />
          {/* <div className="text-title">BSE IoT</div> */}
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <AccountCircle />
              <TextField
                label="Username"
                className="textfield"
                style={{ marginLeft: "8px" }}
                name="username"
                value={state.username}
                onChange={handleChange}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    // Do code here
                    LoginSession();
                  }
                }}
              />
            </div>
          </div>
          <div className="textfield">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
              }}
            >
              <LockIcon />
              <TextField
                label="Password"
                type="password"
                className="textfield"
                style={{ marginLeft: "8px" }}
                name="userpwd"
                value={state.userpwd}
                onChange={handleChange}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    // Do code here
                    LoginSession();
                  }
                }}
              />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div id="captcha"></div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <CachedIcon onClick={getCaptcha} />
                  <TextField
                    label="Captcha"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="captcha"
                    value={state.captcha}
                    onChange={handleChange}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        // Do code here
                        LoginSession();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="space-text">
            <div
              className="text-option"
              id="warning"
              style={{ color: "red" }}
            ></div>
          </div>
          <div className="space-text">
            <div
              className="text-option"
              onClick={() => history.push("/forgotpassword")}
              style={{ cursor: "pointer" }}
            >
              Forgot Password
            </div>
          </div>
          <div
            className="button"
            onClick={LoginSession}
            style={{ cursor: "pointer" }}
          >
            <div className="button-text-white">Login</div>
          </div>
          <div
            className="button-border"
            onClick={() => history.push("/register")}
            style={{ cursor: "pointer" }}
          >
            <div className="button-text">Register</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
