import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Home,
  Dashboard,
  ForgotPass,
  Register,
  Verify,
  VerifyEmail,
  ResetPass,
  Device,
  Settings,
  GroupDashboard,
  Contactus,
} from "./components";

function App() {
  // console.disableYellowBox = true;
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/forgotpassword" component={ForgotPass} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/home" component={GroupDashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/verify" component={Verify} />
          <Route exact path="/changeemailconfirmation" component={VerifyEmail} />
          <Route exact path="/resetpassword" component={ResetPass} />
          <Route exact path="/device" component={Device} />
          <Route exact path="/Settings" component={Settings} />
          <Route exact path="/contact" component={Contactus} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
