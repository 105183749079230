import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import {
  TextField,
  Checkbox,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
import EnhancedEncryptionIcon from "@material-ui/icons/EnhancedEncryption";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { TermnCond } from "./minicomponents";
// Backend
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";
import { AESencrypt } from "./AESEncoder";

function Register() {
  const history = useHistory();
  let [state, setState] = useState({
    username: "",
    useremail: "",
    userfirstname: "",
    userlastname: "",
    userpwd: "",
    cnfrmpwd: "",
    acceptcond: false,
  });
  let [validstate, setValidstate] = useState({
    username: true,
    useremail: true,
    userfirstname: true,
    userlastname: true,
    userpwd: true,
    cnfrmpwd: true,
    acceptcond: true,
  });
  let [atvusn, setAtvusn] = useState({ username: [] }); //Activate username
  const [iswaiting, setIswaiting] = useState(false);
  const [isregistersuccess, setIsregistersuccess] = useState(false);
  const [istermncondread, setIstermncondread] = useState(false);
  function check_valid(name) {
    let value = state[name];
    if (name === "username") {
      if (
        !/^[0-9a-z]+?$/.test(value) ||
        value.length < 4 ||
        atvusn["username"].includes(value)
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "useremail") {
      if (
        value === "" ||
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "acceptcond") {
      if (!value) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "username") {
      if (
        !/^[0-9a-z]+?$/.test(value) ||
        value.length < 4 ||
        atvusn["username"].includes(value)
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "userpwd") {
      if (value.length > 14 || value.length < 6) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "cnfrmpwd") {
      if (value !== state.userpwd || value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "useremail") {
      if (
        value === "" ||
        !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
      ) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else if (name === "acceptcond") {
      if (!value) {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    } else {
      if (value === "") {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: false,
        }));
      } else {
        setValidstate((prevState) => ({
          ...prevState,
          [name]: true,
        }));
      }
    }
  };

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + access_tok,
      },
    });
    //
    if (response.status === 200) {
      history.push("/dashboard");
    } else {
      deleteCookie("bsetok");
    }
  }
  async function getusername() {
    let response = await fetch(config.backend_url + "/be/getusername", {
      method: "GET",
      mode: "cors",
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      //alert(JSON.stringify(data));
      setAtvusn(data);
    }
  }
  async function sentregister() {
    await Object.keys(state).map(check_valid);
    if (
      Object.values(validstate).reduce((a, b) => a + b, 0) === 7 &&
      state.username.length !== 0 &&
      state.useremail.length !== 0 &&
      state.userfirstname.length !== 0 &&
      state.userlastname.length !== 0 &&
      state.userpwd.length !== 0 &&
      state.cnfrmpwd.length !== 0 &&
      state.acceptcond
    ) {
      //Change to waiting
      await setIswaiting(true);
      // Fetch data to be
      let response = await fetch(config.backend_url + "/be/register", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        body: AESencrypt(
          JSON.stringify({
            username: state.username,
            useremail: state.useremail,
            userfirstname: state.userfirstname,
            userlastname: state.userlastname,
            userpwd: state.userpwd,
          }),
          config.aes_key
        ),
      });
      if (response.status === 200) {
        setIsregistersuccess(true);
      } else {
        let data = await response.json()["detail"];
        document.getElementById("warning").innerHTML = data;
        await setIswaiting(false);
      }
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    }
    //get username
    getusername();
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div className="background">
      <Dialog
        open={istermncondread}
        keepMounted
        onClose={() => {
          setIstermncondread(false);
        }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Terms and Conditions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TermnCond />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                acceptcond: false,
              }));
              setIstermncondread(false);
            }}
            color="secondary"
          >
            DISAGREE
          </Button>
          <Button
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                acceptcond: true,
              }));
              setIstermncondread(false);
            }}
            color="primary"
          >
            AGREE
          </Button>
        </DialogActions>
      </Dialog>
      <div className="container home-block">
        {!isregistersuccess ? (
          <div className="home-card">
            <div className="text-title">Register</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <AccountCircle />

                <TextField
                  error={!validstate.username}
                  label="Username"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="username"
                  value={state.username}
                  onChange={handleChange}
                  helperText={
                    !validstate.username && "Cannot use this username."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <EmailIcon />

                <TextField
                  error={!validstate.useremail}
                  label="Email"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="useremail"
                  value={state.useremail}
                  onChange={handleChange}
                  helperText={!validstate.useremail && "Incorrect Email."}
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <AssignmentIndIcon />
                <TextField
                  error={!validstate.userfirstname}
                  label="Firstname"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="userfirstname"
                  value={state.userfirstname}
                  onChange={handleChange}
                  helperText={
                    !validstate.userfirstname && "Please fill your name."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <AssignmentIndIcon />
                <TextField
                  error={!validstate.userlastname}
                  label="Lastname"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="userlastname"
                  value={state.userlastname}
                  onChange={handleChange}
                  helperText={
                    !validstate.userlastname && "Please fill your lastname."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <LockIcon />
                <TextField
                  error={!validstate.userpwd}
                  label="Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="userpwd"
                  value={state.userpwd}
                  onChange={handleChange}
                  helperText={
                    !validstate.userpwd &&
                    "Password must be included 6-14 character."
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <EnhancedEncryptionIcon />
                <TextField
                  error={!validstate.cnfrmpwd}
                  label="Confirm Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="cnfrmpwd"
                  value={state.cnfrmpwd}
                  onChange={handleChange}
                  helperText={!validstate.cnfrmpwd && "Password is not match."}
                />
              </div>
            </div>
            <div className="space-text">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Checkbox
                  error={!validstate.acceptcond}
                  color="default"
                  style={{ marginLeft: "-8px" }}
                  checked={state.acceptcond}
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      acceptcond: !state.acceptcond,
                    }))
                  }
                />

                <div className="text-option">
                  I have read and agree to the{" "}
                  <strong
                    style={{
                      cursor: "pointer",
                      color: "#0D4476",
                    }}
                    onClick={() => {
                      setIstermncondread(true);
                    }}
                  >
                    <i>Terms and Conditions</i>
                  </strong>
                </div>
              </div>
              {!validstate.acceptcond && (
                <FormHelperText error>
                  Please Accept Term and Condition.
                </FormHelperText>
              )}
            </div>
            <div id="warning" style={{ color: "red" }}></div>
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={sentregister}
            >
              {!iswaiting ? (
                <div className="button-text-white">Register</div>
              ) : (
                <div className="button-text-white">
                  <Spinner animation="border" variant="light" size="sm" />{" "}
                  Waiting...
                </div>
              )}
            </div>
            <div
              className="button-border"
              onClick={() => history.replace("/")}
              style={{ cursor: "pointer" }}
            >
              <div className="button-text">Back</div>
            </div>
          </div>
        ) : (
          <div className="home-card">
            <div className="text-title">Register Success!!!</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                We already sent activation link to your email.
              </div>
            </div>
            <div
              className="button"
              style={{ cursor: "pointer" }}
              onClick={() => history.replace("/")}
            >
              <div className="button-text-white">OK</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;
