import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import {
  IconButton,
  TextField,
  Button,
  InputAdornment,
  Checkbox,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";
import { Navbar, DeviceListCard, OpenMapcomponent } from "./minicomponents";
import React, { useState, useEffect } from "react";
import CropFreeIcon from "@material-ui/icons/CropFree";
import QrReader from "react-qr-reader";

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Backend
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";

import { makeStyles } from "@material-ui/core/styles";
import { AESencrypt, AESdecrypt } from "./AESEncoder";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const options = [
  "Online Normal",
  "Offline Normal",
  "Online Warning",
  "Offline Warning",
];
let mqtt = require("mqtt");
function Dashboard(props) {
  let wrapper = React.createRef();
  const history = useHistory();
  let [userdata, setUserdata] = useState(null);
  const [filtered, setFiltered] = useState("");
  let [adddevicestate, setAdddevicestate] = useState(0);
  const classes = useStyles();
  let [addserialdata, setAddserialdata] = useState({
    serial: "",
    serialname: "",
    description: "",
    machinetype: "",
  });
  let [state, setState] = useState({
    editgroupstate: false,
    deletegroupstate: false,
    filterstate: false,
    openmapstate: false,
    newgroupname: "",
    newdescription: "",
    deletepassword: "",
    validnewname: true,
  });
  let [devicestatus, setDevicestatus] = useState({});
  let [validserialname, setValidserialname] = useState(true);
  // let query = new URLSearchParams(props.location.search);
  let [currenttime, setCurrenttime] = useState(
    new Date(
      Date.now() +
        new Date().getTimezoneOffset() * 60 * 1000 +
        7 * 60 * 60 * 1000
    )
  );
  //Filtered
  const [selected, setSelected] = useState([...options]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + access_tok,
      },
      body: "*",
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      if (
        !Object.keys(data.grouplist).includes(localStorage.getItem("groupname"))
      ) {
        history.push("/home");
        return;
      }
      let groupposition = [];
      for (let i in data.serialposition) {
        if (
          JSON.parse(
            data.grouplist[localStorage.getItem("groupname")].device
          ).includes(data.serialposition[i].serial)
        ) {
          let values = data.serialposition[i];
          groupposition.push(values);
          let last_update_time = new Date(values.ts);
          let iswarning = values.warning.iswarning;
          let warningdetail = values.warning.warningdetail;
          let deviceposition = {
            lat: parseFloat(values.lat),
            lng: parseFloat(values.lgn),
          };
          setDevicestatus((prevstate) => ({
            ...prevstate,
            [values.serial]: {
              iswarning: iswarning,
              warningdetail: warningdetail,
              last_update_time: last_update_time,
              deviceposition: deviceposition,
            },
          }));
        }
      }
      data.serialposition = groupposition;
      setUserdata(data);
      const clientId =
        "reactuser_" +
        data.username +
        (Math.floor(Math.random() * 1000000) + 1).toString();
      const options = {
        keepalive: 60,
        username: config.mqtt_username,
        password: config.mqtt_pwd,
        clientId: clientId,
        protocolId: "MQTT",
        protocolVersion: 4,
        clean: true,
        reconnectPeriod: 1000,
        connectTimeout: 30 * 1000,
        will: {
          topic: "WillMsg",
          payload: "Connection Closed abnormally..!",
          qos: 0,
          retain: false,
        },
      };
      const client = mqtt.connect(config.mqtt_endpoint, options);
      client.on("error", (err) => {
        console.log("Connection error: ", err);
        client.end();
      });

      client.on("reconnect", () => {
        // console.log("Reconnecting...");
      });
      data.serialposition.map((v) => {
        client.subscribe("updatedata/" + v.serial + "/+", { qos: 0 });
        client.subscribe("updatedatas/" + v.serial + "/+", { qos: 0 });
      });
      client.on("message", (topic, message, packet) => {
        let data = message.toString();
        if (topic.split("/")[0] === "updatedatas") {
          data = AESdecrypt(data, config.device_aes_key);
        }
        data = JSON.parse(data);
        let payload = {
          iswarning: data.warning.iswarning,
          warningdatail: data.warning.warningdatail,
          last_update_time: new Date(
            Date.now() +
              new Date().getTimezoneOffset() * 60 * 1000 +
              7 * 60 * 60 * 1000
          ),
          deviceposition: {
            lat: parseFloat(data.lat),
            lng: parseFloat(data.lgn),
          },
        };
        setDevicestatus((prevstate) => ({
          ...prevstate,
          [data.serial]: payload,
        }));
      });
    } else {
      deleteCookie("bsetok");
      history.push("/");
    }
  }

  const handleadddeviceChange = (e) => {
    const { name, value } = e.target;
    setAddserialdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "serialname") {
      if (value === "") {
        setValidserialname(false);
      } else {
        setValidserialname(true);
      }
    }
  };

  async function sendadddevicerequest() {
    if (addserialdata.serialname === "") {
      setValidserialname(false);
    } else {
      let response = await fetch(config.backend_url + "/addserial", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + accessCookie("bsetok"),
        },
        body: JSON.stringify({
          ...addserialdata,
          groupname: localStorage.getItem("groupname"),
        }),
      });
      //
      if (response.status === 200) {
        checkUserData(accessCookie("bsetok"));
        setAdddevicestate(0);
      } else {
        document.getElementById("adddevicewarning").innerHTML =
          "Please Try Again";
      }
    }
  }
  async function checkserial() {
    let response = await fetch(config.backend_url + "/getmachinetype", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        serialid: addserialdata.serial,
      }),
    });
    if (response.status === 200) {
      let data = await response.json();
      setAddserialdata((prevState) => ({
        ...prevState,
        machinetype: data.MachineType,
      }));
      setAdddevicestate(2);
    } else {
    }
  }
  async function editgroupname() {
    let response = await fetch(config.backend_url + "/editgroup", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify({
        groupname: localStorage.getItem("groupname"),
        newgroupname: state.newgroupname,
        newdescription: state.newdescription,
      }),
    });
    //
    if (response.status === 200) {
      history.push("/dashboard?groupname=" + state.newgroupname);
      let data = await response.json();
      let groupposition = [];
      for (let i in data.serialposition) {
        if (
          JSON.parse(data.grouplist[state.newgroupname].device).includes(
            data.serialposition[i].serial
          )
        ) {
          groupposition.push(data.serialposition[i]);
        }
      }
      data.serialposition = groupposition;
      setUserdata(data);
      setState((prevstate) => ({
        ...prevstate,
        editgroupstate: false,
      }));
    } else {
      let data = await response.json();
      document.getElementById("editgroupwarning").innerHTML = data.detail;
    }
  }
  async function deletegroup() {
    let response = await fetch(config.backend_url + "/deletegroup", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: AESencrypt(
        JSON.stringify({
          groupname: localStorage.getItem("groupname"),
          password: state.deletepassword,
        }),
        config.aes_key
      ),
    });
    //
    if (response.status === 200) {
      history.push("/home");
    } else {
      let data = await response.json();
      document.getElementById("deletegroupwarning").innerHTML = data.detail;
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  setInterval(() => {
    setCurrenttime(
      new Date(
        Date.now() +
          new Date().getTimezoneOffset() * 60 * 1000 +
          7 * 60 * 60 * 1000
      )
    );
  }, 1000);

  const handleErrorWebCam = (error) => {
    // console.log(error);
  };
  const handleScanWebCam = (result) => {
    if (result) {
      // setScanResult(result);
      setAddserialdata((prevState) => ({
        ...prevState,
        serial: result,
      }));
      setAdddevicestate(1);
    }
  };

  return (
    <div ref={wrapper}>
      {/* <Backdrop open={adddevicestate !== 0} className={classes.backdrop}> */}
      <Modal
        show={adddevicestate !== 0}
        // onHide={() => {
        //   setAdddevicestate(0);
        //   // document.body.style.overflow = "visible";
        // }}
        backdrop="static"
        centered
        // transparent={true}
        // style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          {adddevicestate === 1 && (
            <div className="popup-card2">
              <div className="text-title">Add Device</div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    error={Object.keys(userdata.serial).includes(
                      addserialdata.serial
                    )}
                    label="Serial ID"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="serial"
                    value={addserialdata.serial}
                    onChange={handleadddeviceChange}
                    helperText={
                      Object.keys(userdata.serial).includes(
                        addserialdata.serial
                      ) && "This serial ID is already used."
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setAdddevicestate(3);
                            }}
                            // onClick={handleClickShowPassword}
                            // onMouseDown={}
                          >
                            <CropFreeIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="space-text">
                <div
                  className="text-option"
                  id="adddevicewarning"
                  style={{ color: "red" }}
                ></div>
              </div>
              <Button
                disabled={
                  Object.keys(userdata.serial).includes(addserialdata.serial) ||
                  addserialdata.serial === ""
                }
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                style={{ width: "100%", marginBottom: "8px" }}
                onClick={checkserial}
              >
                Next
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="default"
                style={{ width: "100%" }}
                onClick={() => {
                  setAdddevicestate(0);
                  // document.body.style.overflow = "visible";
                }}
              >
                Close
              </Button>
            </div>
          )}
          {adddevicestate === 2 && (
            <div className="popup-card2">
              <div className="text-title">Add Device</div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/deviceimage/" + addserialdata.machinetype + ".png"}
                  style={{ width: "150px" }}
                  alt=""
                />
              </div>

              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    disabled
                    label="Serial ID"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="serial"
                    value={addserialdata.serial}
                    onChange={handleadddeviceChange}
                  />
                </div>
              </div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    error={!validserialname}
                    label="Site ID"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="serialname"
                    value={addserialdata.serialname}
                    onChange={handleadddeviceChange}
                    helperText={!validserialname && "Please Fill Site ID."}
                  />
                </div>
              </div>
              <div className="textfield">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                  }}
                >
                  <TextField
                    label="Zone-SiteName-Description"
                    className="textfield"
                    style={{ marginLeft: "8px" }}
                    name="description"
                    value={addserialdata.description}
                    onChange={handleadddeviceChange}
                  />
                </div>
              </div>
              <div className="space-text">
                <div
                  className="text-option"
                  id="adddevicewarning"
                  style={{ color: "red" }}
                ></div>
              </div>
              <Button
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                style={{ width: "100%", marginBottom: "8px" }}
                onClick={sendadddevicerequest}
              >
                Add Device
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="default"
                style={{ width: "100%" }}
                onClick={() => {
                  setAddserialdata((prevState) => ({
                    ...prevState,
                    machinetype: "",
                  }));
                  setAdddevicestate(1);
                }}
              >
                Back
              </Button>
            </div>
          )}
          {adddevicestate === 3 && (
            <div className="popup-card2">
              <div className="text-title">Add Device</div>
              <QrReader
                error={Object.keys(userdata.serial).includes(
                  addserialdata.serial
                )}
                value={addserialdata.serial}
                delay={200}
                style={{ width: "100%" }}
                onError={handleErrorWebCam}
                onScan={handleScanWebCam}
              />
              <div className="space-text">
                <div
                  className="text-option"
                  id="checkdevicewarning"
                  style={{ color: "red" }}
                ></div>
              </div>
              <Button
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                style={{ width: "100%", marginBottom: "8px" }}
                onClick={() => {
                  setAdddevicestate(1);
                }}
              >
                Back
              </Button>
              <Button
                variant="outlined"
                size="large"
                color="default"
                style={{ width: "100%" }}
                onClick={() => {
                  setAdddevicestate(0);
                }}
              >
                Close
              </Button>
            </div>
          )}
        </div>
        {/* </Backdrop> */}
      </Modal>
      <Modal show={state.editgroupstate} backdrop="static" centered>
        {/* <Backdrop open={state.editgroupstate} className={classes.backdrop}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "90%",
          }}
        >
          <div className="popup-card2">
            <div className="text-title">Edit Group Name</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  error={state.newgroupname === ""}
                  label="Groupname"
                  style={{ marginLeft: "8px" }}
                  name="newgroupname"
                  className="textfield"
                  value={state.newgroupname}
                  onChange={(e) => {
                    setState((prevstate) => ({
                      ...prevstate,
                      newgroupname: e.target.value,
                    }));
                  }}
                  helperText={
                    (state.newgroupname === "" && "Please fill Groupname") ||
                    (state.newgroupname !== localStorage.getItem("groupname") &&
                      Object.keys(userdata.grouplist).includes(
                        state.newgroupname
                      ) &&
                      "This group name is already used")
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Description"
                  style={{ marginLeft: "8px" }}
                  name="newdescription"
                  className="textfield"
                  value={state.newdescription}
                  onChange={(e) => {
                    setState((prevstate) => ({
                      ...prevstate,
                      newdescription: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="space-text">
              <div
                className="text-option"
                id="editgroupwarning"
                style={{ color: "red" }}
              ></div>
            </div>
            <Button
              disabled={
                state.newgroupname === "" ||
                (state.newgroupname !== localStorage.getItem("groupname") &&
                  Object.keys(userdata.grouplist).includes(state.newgroupname))
              }
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={editgroupname}
            >
              Confirm
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  editgroupstate: false,
                }));
                document.getElementById("editgroupwarning").innerHTML = "";
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      {/* </Backdrop> */}
      <Modal
        show={state.deletegroupstate}
        backdrop="static"
        centered
        // transparent={true}
        // style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      >
        {/* <Backdrop open={state.deletegroupstate} className={classes.backdrop}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "90%",
          }}
        >
          <div className="popup-card2">
            <div className="text-title">Delete Group</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Password"
                  type="password"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="deletepassword"
                  value={state.deletepassword}
                  onChange={(e) => {
                    setState((prevstate) => ({
                      ...prevstate,
                      deletepassword: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="space-text">
              <div
                className="text-option"
                id="deletegroupwarning"
                style={{ color: "red" }}
              ></div>
            </div>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={deletegroup}
            >
              Delete Group
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  deletegroupstate: false,
                }));
                document.getElementById("deletegroupwarning").innerHTML = "";
              }}
            >
              Close
            </Button>
          </div>
        </div>
        {/* </Backdrop> */}
      </Modal>
      <Modal
        show={state.openmapstate}
        backdrop="static"
        centered
        onHide={() => {
          setState((prevstate) => ({
            ...prevstate,
            openmapstate: false,
          }));
        }}
      >
        <Modal.Header>
          <Modal.Title>Device Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              minWidth: "90%",
            }}
          >
            <div className="popup-card2">
              {userdata && (
                <div
                  style={{
                    width: `100%`,
                    height: "60vh",
                    alignitems: "center",
                  }}
                >
                  <OpenMapcomponent
                    markers={userdata.serialposition.map((values) => {
                      // return { lat: values.lat, lng: values.lgn };
                      return devicestatus[values.serial].deviceposition;
                    })}
                    zoomstart={5}
                    Devicename={userdata.serialposition.map(
                      (values) => userdata.serial[values.serial].serialname
                    )}
                    DeviceType={userdata.serialposition.map(
                      (values) => values.serial
                    )}
                    //logo={["/deviceicons/A.png"]}
                    logo={userdata.serialposition.map((values) => {
                      if (
                        currenttime -
                          devicestatus[values.serial].last_update_time <
                        config.offline_time * 60 * 1000
                      ) {
                        if (devicestatus[values.serial].iswarning) {
                          return (
                            "/deviceicons/" +
                            userdata.serial[values.serial].machinetype +
                            "/" +
                            userdata.serial[values.serial].machinetype +
                            "-fail-online.png"
                          );
                        } else {
                          return (
                            "/deviceicons/" +
                            userdata.serial[values.serial].machinetype +
                            "/" +
                            userdata.serial[values.serial].machinetype +
                            "-online.png"
                          );
                        }
                      } else {
                        if (devicestatus[values.serial].iswarning) {
                          return (
                            "/deviceicons/" +
                            userdata.serial[values.serial].machinetype +
                            "/" +
                            userdata.serial[values.serial].machinetype +
                            "-fail-offline.png"
                          );
                        } else {
                          return (
                            "/deviceicons/" +
                            userdata.serial[values.serial].machinetype +
                            "/" +
                            userdata.serial[values.serial].machinetype +
                            "-offline.png"
                          );
                        }
                      }
                    })}
                    // groupname={localStorage
                    //   .getItem("groupname")
                    //   .replace(" ", "_")}
                  />
                </div>
              )}
              <Button
                variant="contained"
                size="large"
                color="primary"
                style={{ width: "100%", marginTop: "8px" }}
                onClick={() => {
                  setState((prevstate) => ({
                    ...prevstate,
                    openmapstate: false,
                  }));
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={state.filterstate} backdrop="static" centered>
        {/* <Backdrop open={state.editgroupstate} className={classes.backdrop}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            minWidth: "90%",
          }}
        >
          <div className="popup-card2">
            <div className="text-title">Filter Device</div>
            <div>
              <MenuItem
                value="all"
                classes={{
                  root: isAllSelected ? classes.selectedAll : "",
                }}
                onClick={() => {
                  if (
                    options.length > 0 &&
                    selected.length === options.length
                  ) {
                    setSelected([]);
                  } else {
                    setSelected([...options]);
                  }
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    // classes={{ indeterminate: classes.indeterminateColor }}
                    color="primary"
                    checked={isAllSelected}
                    indeterminate={
                      selected.length > 0 && selected.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {options.map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  onClick={() => {
                    let new_selected = selected;
                    if (selected.indexOf(option) > -1) {
                      new_selected.splice(selected.indexOf(option), 1);
                    } else {
                      new_selected.push(option);
                    }
                    setSelected(new_selected);
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={selected.indexOf(option) > -1}
                      color="primary"
                    />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </div>

            <Button
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={() => {
                setState((prevstate) => ({
                  ...prevstate,
                  filterstate: false,
                }));
              }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal>
      <div className="background">
        <div className="container page-block">
          <Navbar />
          <div className="device-card">
            {userdata && (
              <div className="data-card">
                {/* {JSON.stringify(userdata.serialposition)} */}
                {/* {JSON.stringify(devicestatus)} */}
                {/* {JSON.stringify(currenttime)} */}
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <IconButton
                    style={{
                      backgroundColor: "#e4e4e4",
                      borderRadius: "32px",
                      width: "32px",
                      height: "32px",
                    }}
                    onClick={() => {
                      history.push("/home");
                    }}
                  >
                    <ArrowBackIosOutlinedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>

                  <div
                    className="text-title-dashboard"
                    style={{
                      margin: "0px",
                      marginRight: "8px",
                      marginLeft: "8px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {/* Groupname: {localStorage.getItem("groupname")} */}
                    {localStorage.getItem("groupname")}
                  </div>
                  <IconButton
                    style={{
                      backgroundColor: "#e4e4e4",
                      borderRadius: "32px",
                      width: "32px",
                      height: "32px",
                      marginRight: "8px",
                    }}
                    onClick={() => {
                      setState((prevstate) => ({
                        ...prevstate,
                        editgroupstate: true,
                        newgroupname: localStorage.getItem("groupname"),
                        newdescription:
                          userdata.grouplist[localStorage.getItem("groupname")]
                            .description,
                        validnewname: true,
                      }));
                    }}
                  >
                    <EditIcon style={{ width: "20px", height: "20px" }} />
                  </IconButton>
                  <IconButton
                    style={{
                      backgroundColor: "#e4e4e4",
                      borderRadius: "32px",
                      width: "32px",
                      height: "32px",
                    }}
                    onClick={() => {
                      setState((prevstate) => ({
                        ...prevstate,
                        deletegroupstate: true,
                        deletepassword: "",
                      }));
                    }}
                  >
                    <DeleteIcon style={{ width: "20px", height: "20px" }} />
                  </IconButton>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="text-title" style={{ textAlign: "left" }}>
                      Map
                    </div>
                    <div
                      style={{
                        width: `100%`,
                        height: "60vh",
                        alignitems: "center",
                      }}
                    >
                      <OpenMapcomponent
                        markers={userdata.serialposition.map((values) => {
                          // return { lat: values.lat, lng: values.lgn };
                          return devicestatus[values.serial].deviceposition;
                        })}
                        zoomstart={5}
                        Devicename={userdata.serialposition.map(
                          (values) => userdata.serial[values.serial].serialname
                        )}
                        DeviceType={userdata.serialposition.map(
                          (values) => values.serial
                        )}
                        //logo={["/deviceicons/A.png"]}
                        logo={userdata.serialposition.map((values) => {
                          return (
                            "/deviceicons/" +
                            userdata.serial[values.serial].machinetype +
                            ".png"
                          );
                        })}
                        // logo={userdata.serialposition.map((values) => {
                        //   if (
                        //     currenttime -
                        //       devicestatus[values.serial].last_update_time <
                        //     config.offline_time * 60 * 1000
                        //   ) {
                        //     if (devicestatus[values.serial].iswarning) {
                        //       return (
                        //         "/deviceicons/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "-fail-online.png"
                        //       );
                        //     } else {
                        //       return (
                        //         "/deviceicons/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "-online.png"
                        //       );
                        //     }
                        //   } else {
                        //     if (devicestatus[values.serial].iswarning) {
                        //       return (
                        //         "/deviceicons/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "-fail-offline.png"
                        //       );
                        //     } else {
                        //       return (
                        //         "/deviceicons/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "/" +
                        //         userdata.serial[values.serial].machinetype +
                        //         "-offline.png"
                        //       );
                        //     }
                        //   }
                        // })}
                        // groupname={localStorage.getItem("groupname").replace(" ", "_")}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* <div className="text-title-dashboard">Devices</div> */}
                      <div></div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        {/* <IconButton
                    style={{
                      backgroundColor: "#ffffff",
                      borderRadius: "32px",
                      width: "32px",
                      height: "32px",
                    }}
                  >
                    <DeleteIcon style={{ width: "20px", height: "20px" }} />
                  </IconButton> */}
                        {/* <IconButton
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "32px",
                            width: "32px",
                            height: "32px",
                            marginLeft: "10px",
                          }}
                          // onClick={onMapOpen}
                          onClick={() => {
                            setState((prevstate) => ({
                              ...prevstate,
                              openmapstate: true,
                            }));
                          }}
                        >
                          <img
                            src="/customicons/map-icon.png"
                            style={{ width: "20px", height: "20px" }}
                            alt=""
                          />
                        </IconButton> */}
                        <IconButton
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "32px",
                            width: "32px",
                            height: "32px",
                            marginLeft: "10px",
                          }}
                          onClick={(e) => {
                            setAdddevicestate(1);
                            setValidserialname(true);
                            setAddserialdata({
                              serial: "",
                              serialname: "",
                              description: "",
                              machinetype: "",
                            });
                          }}
                        >
                          <PlaylistAddIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "64px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        marginBottom: "16px",
                        // flexDirection: "column",
                      }}
                    >
                      <TextField
                        label="Search bar"
                        // className="textfield"
                        name="Search bar"
                        value={filtered}
                        onChange={(e) => setFiltered(e.target.value)}
                        // style={{ marginBottom: "16px" }}
                        style={{ flex: 1 }}
                      />
                      <IconButton
                        style={{ width: "32px", height: "32px" }}
                        onClick={() => {
                          setState((prevstate) => ({
                            ...prevstate,
                            filterstate: true,
                          }));
                        }}
                      >
                        <FilterListIcon
                          style={{ width: "24px", height: "24px" }}
                        />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        height: "52vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {userdata.grouplist[localStorage.getItem("groupname")] &&
                        JSON.parse(
                          userdata.grouplist[localStorage.getItem("groupname")]
                            .device
                        ).map((device, index) => {
                          if (
                            !filtered ||
                            filtered === "" ||
                            userdata.serial[device].serialname.includes(
                              filtered
                            )
                            // ||
                            // device.includes(filtered)
                          ) {
                            return (
                              ((currenttime -
                                devicestatus[device].last_update_time <
                                config.offline_time * 60 * 1000 &&
                                devicestatus[device].iswarning &&
                                selected.indexOf("Online Warning") > -1) ||
                                (currenttime -
                                  devicestatus[device].last_update_time >=
                                  config.offline_time * 60 * 1000 &&
                                  devicestatus[device].iswarning &&
                                  selected.indexOf("Offline Warning") > -1) ||
                                (currenttime -
                                  devicestatus[device].last_update_time <
                                  config.offline_time * 60 * 1000 &&
                                  !devicestatus[device].iswarning &&
                                  selected.indexOf("Online Normal") > -1) ||
                                (currenttime -
                                  devicestatus[device].last_update_time >=
                                  config.offline_time * 60 * 1000 &&
                                  !devicestatus[device].iswarning &&
                                  selected.indexOf("Offline Normal") > -1)) && (
                                <DeviceListCard
                                  devicestatus={devicestatus[device]}
                                  key={"device" + index}
                                  machinetype={
                                    userdata.serial[device].machinetype
                                  }
                                  serialname={
                                    userdata.serial[device].serialname
                                  }
                                  description={
                                    userdata.serial[device].description
                                  }
                                  status={device}
                                  // icon={<ListIcon />}
                                  // icon={devicestatus[device].lightstatus}
                                  icon={
                                    <div>
                                      {currenttime -
                                        devicestatus[device].last_update_time <
                                      config.offline_time * 60 * 1000 ? (
                                        <div>
                                          {devicestatus[device].iswarning ? (
                                            <div className="status-device-fail-online"></div>
                                          ) : (
                                            <div className="status-device-online"></div>
                                          )}
                                        </div>
                                      ) : (
                                        <div>
                                          {devicestatus[device].iswarning ? (
                                            <div className="status-device-fail-offline"></div>
                                          ) : (
                                            <div className="status-device-offline"></div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  }
                                  link={"/device?serialid=" + device}
                                />
                              )
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <LinechartApex /> */}
      </div>
    </div>
  );
}

export default Dashboard;
