import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Backend
import config from "./config.json";
import { TextField, Button } from "@material-ui/core";
import { deleteCookie, accessCookie } from "./CookieManagement.js";
import { Navbar, StatusPropCard } from "./minicomponents";
import { ScreenDevice } from "./devicesdashboard";
import { AESencrypt } from "./AESEncoder";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";

function Device(props) {
  const history = useHistory();
  let [state, setState] = useState({
    editdevicestate: false,
    deletedevicestate: false,
    openmapstate: false,
    newdevicename: "",
    newdescription: "",
    deletepassword: "",
    validnewname: true,
    notimanagerstate: false,
    notificationstatus: false,
  });
  let [userdata, setUserdata] = useState(null);
  let [machinetype, setMachinetype] = useState(null);

  let query = new URLSearchParams(props.location.search);
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + access_tok,
      },
      body: JSON.stringify([query.get("serialid")]),
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      setUserdata(data);
      if (Object.keys(data.serial).includes(query.get("serialid"))) {
        setMachinetype(data.serial[query.get("serialid")].machinetype);
        setState((prevstate) => ({
          ...prevstate,
          newdevicename: data.serial[query.get("serialid")].serialname,
          newdescription: data.serial[query.get("serialid")].description,
          notificationstatus: data.notification.includes(query.get("serialid")),
        }));
      } else {
        history.push("/home");
      }
    } else {
      deleteCookie("bsetok");
      history.push("/");
    }
  }
  async function editdevicename() {
    let response = await fetch(config.backend_url + "/editserialname", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify({
        serialname: state.newdevicename,
        description: state.newdescription,
        serialid: query.get("serialid"),
      }),
    });
    //
    if (response.status === 200) {
      checkUserData(accessCookie("bsetok"));
      setState((prevstate) => ({
        ...prevstate,
        editdevicestate: false,
      }));
      document.getElementById("editdevicewarning").innerHTML = "";
    } else {
      document.getElementById("editdevicewarning").innerHTML =
        "Please Try Again";
    }
  }
  async function deletedevice() {
    let response = await fetch(config.backend_url + "/deleteserial", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: AESencrypt(
        JSON.stringify({
          userpwd: state.deletepassword,
          serial: query.get("serialid"),
        }),
        config.aes_key
      ),
    });
    //
    if (response.status === 200) {
      history.push(
        // "/dashboard?groupname=" +
        //   userdata.serial[query.get("serialid")].groupname
        "/dashboard"
      );
    } else {
      let data = await response.json();
      document.getElementById("deletedevicewarning").innerHTML = data.detail;
    }
  }
  async function changenotification() {
    let response = await fetch(config.backend_url + "/be/notification", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + accessCookie("bsetok"),
      },
      body: JSON.stringify({
        mode: state.notificationstatus,
        serialid: query.get("serialid"),
      }),
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      setState((prevstate) => ({
        ...prevstate,
        notificationstatus: data.notification.includes(query.get("serialid")),
        notimanagerstate: false,
      }));
      setUserdata((prevstate) => ({
        ...prevstate,
        notification: data.notification,
      }));
    } else {
    }
  }

  useEffect(() => {
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);
  return (
    <div className="background">
      {userdata && (
        <div className="container page-block">
          <Modal show={state.editdevicestate} backdrop="static" centered>
            {/* <Backdrop open={state.editdevicestate} className={classes.backdrop}> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "90%",
              }}
            >
              <div className="popup-card2">
                <div className="text-title">Edit Site ID</div>
                <div className="textfield">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <TextField
                      error={state.newdevicename === ""}
                      label="Site ID"
                      style={{ marginLeft: "8px" }}
                      name="newdevicename"
                      className="textfield"
                      value={state.newdevicename}
                      onChange={(e) => {
                        setState((prevstate) => ({
                          ...prevstate,
                          newdevicename: e.target.value,
                        }));
                      }}
                      helperText={
                        state.newdevicename === "" && "Please fill Site ID"
                      }
                    />
                  </div>
                </div>
                <div className="textfield">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <TextField
                      label="Zone-SiteName-Description"
                      style={{ marginLeft: "8px" }}
                      name="newdescription"
                      className="textfield"
                      value={state.newdescription}
                      onChange={(e) => {
                        setState((prevstate) => ({
                          ...prevstate,
                          newdescription: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="space-text">
                  <div
                    className="text-option"
                    id="editdevicewarning"
                    style={{ color: "red" }}
                  ></div>
                </div>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  disableElevation
                  style={{ width: "100%", marginBottom: "8px" }}
                  onClick={editdevicename}
                >
                  Confirm
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="default"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setState((prevstate) => ({
                      ...prevstate,
                      editdevicestate: false,
                      newdevicename:
                        userdata.serial[query.get("serialid")].serialname,
                      newdescription:
                        userdata.serial[query.get("serialid")].description,
                    }));
                    document.getElementById("editdevicewarning").innerHTML = "";
                  }}
                >
                  Close
                </Button>
              </div>
            </div>
            {/* </Backdrop> */}
          </Modal>
          <Modal show={state.deletedevicestate} backdrop="static" centered>
            {/* <Backdrop open={state.deletedevicestate} className={classes.backdrop}> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "90%",
              }}
            >
              <div className="popup-card2">
                <div className="text-title">Delete Device</div>
                <div className="textfield">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <TextField
                      label="Password"
                      type="password"
                      className="textfield"
                      style={{ marginLeft: "8px" }}
                      name="deletepassword"
                      value={state.deletepassword}
                      onChange={(e) => {
                        setState((prevstate) => ({
                          ...prevstate,
                          deletepassword: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="space-text">
                  <div
                    className="text-option"
                    id="deletedevicewarning"
                    style={{ color: "red" }}
                  ></div>
                </div>
                {/*<div
                className="button-danger"
                style={{ cursor: "pointer" }}
                onClick={deletedevice}
              >
                <div className="button-text-white">DELETE DEVICE</div>
              </div>*/}
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  disableElevation
                  style={{ width: "100%", marginBottom: "8px" }}
                  onClick={deletedevice}
                >
                  Delete Device
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="default"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setState((prevstate) => ({
                      ...prevstate,
                      deletedevicestate: false,
                      deletepassword: "",
                    }));
                    document.getElementById("deletedevicewarning").innerHTML =
                      "";
                  }}
                >
                  Close
                </Button>
                {/*<div
                className="button-border"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setState((prevstate) => ({
                    ...prevstate,
                    deletedevicestate: false,
                  }));
                  document.getElementById("deletedevicewarning").innerHTML = "";
                }}
              >
                <div className="button-text">Close</div>
              </div>*/}
              </div>
            </div>
            {/* </Backdrop> */}
          </Modal>
          <Modal show={state.notimanagerstate} backdrop="static" centered>
            {/* <Backdrop open={state.deletedevicestate} className={classes.backdrop}> */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                minWidth: "90%",
              }}
            >
              <div className="popup-card2">
                <div className="text-title">Notification Setting</div>
                <StatusPropCard
                  title="Notification"
                  name="notificationstatus"
                  status={state.notificationstatus ? "on" : "off"}
                  icon={
                    state.notificationstatus ? (
                      <NotificationsActiveIcon />
                    ) : (
                      <NotificationsOffIcon />
                    )
                  }
                  mode="switch"
                  color="primary"
                  onChange={(e, newValue) => {
                    setState((prevstate) => ({
                      ...prevstate,
                      notificationstatus: newValue,
                    }));
                  }}
                  value={state.notificationstatus}
                />
                <div className="textfield">
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    disableElevation
                    style={{ width: "100%", marginBottom: "8px" }}
                    onClick={changenotification}
                  >
                    OK
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    color="default"
                    style={{ width: "100%" }}
                    onClick={() => {
                      setState((prevstate) => ({
                        ...prevstate,
                        notimanagerstate: false,
                      }));
                    }}
                  >
                    Close
                  </Button>
                  {/*<div
                className="button-border"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setState((prevstate) => ({
                    ...prevstate,
                    deletedevicestate: false,
                  }));
                  document.getElementById("deletedevicewarning").innerHTML = "";
                }}
              >
                <div className="button-text">Close</div>
              </div>*/}
                </div>
              </div>
              {/* </Backdrop> */}
            </div>
          </Modal>
          <Navbar />
          <ScreenDevice
            userdata={userdata}
            serialid={query.get("serialid")}
            setState={setState}
            machinetype={machinetype}
          />
        </div>
      )}
    </div>
  );
}

export default Device;
