function TermnCond() {
  return (
    <div>
      <p>
        เพื่อปกป้องสิทธิของผู้ใช้บริการ
        โปรดอ่านเงื่อนไขการให้บริการทั้งหมดและตกลงที่จะปฏิบัติตามข้อตกลงและเงื่อนไขทั้งหมด
        หากคุณไม่เห็นด้วยในข้อกำหนดและเงื่อนไข (ตามที่กำหนดไว้ด้านล่าง)
        และต้องการที่จะยุติการใช้บริการ
        กรุณาอย่าดำเนินการโดยใชแอปพลิเคชันหรือเว็บไซต์นี้
      </p>
      <p>
        ข้าพเจ้ายอมรับว่าการติดตั้ง การใช้งาน และการรับบริการต่าง
        ๆผ่านทางแอปพลิเคชั่นหรือเว็บไซต์ www.bseviot.com
        เป็นไปตามข้อกำหนดและเงื่อนไข ดังต่อไปนี้
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>ขอบเขตของข้อตกลงและเงื่อนไขการใช้บริการ</li>
      </ol>
      <p>
        ข้อตกลงการใช้งานต่อไปนี้ จะเป็นข้อตกลงการใช้ระหว่างผู้ใช้งานกับเว็บแอฟ
        www.bseviot.com โดยการเข้าถึงหรือเข้าใช้บริการเว็บแอปพิเคชั่น
        www.bseviot.com ภายใต้การควบคุมดูแลของบริษัท บี.เอส.อี อิเล็คทรอนิค
        จำกัด ( B.S.E ELECTRONIC ) จะหมายถึง ท่านยอมรับข้อตกลง
        ข้อกำหนดและเงื่อนไขการใช้บริการ ที่ปรากฎบนเว็บแอปพิเคชั่น
        ว่ามีผลผูกพันตามกฎหมาย ( เงื่อนไข ) และท่านตกลงยอมรับ และปฏิบัติ
        นโยบายส่วนตัวของเว็บแอปพิเคชั่น และยอมรับที่จะปฏิบัติตามกฎระเบียบอื่นๆ
        บนเว็บแอปพิเคชั่น เช่น วิธีการใช้
        วิธีปฎิบัติและขั้นตอนหรือเงื่อนไขสำหรับการเข้าถึงหรือการใช้บริการของเว็ปแอปพิเคชั่น
        www.bseviot.com
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>การยอมรับข้อตกลงและเงื่อนไขการใช้บริการ</li>
      </ol>
      <p>
        ผู้ใช้บริการจะต้องตกลงและปฏิบัติตามเงื่อนไขการใช้บริการฉบับนี้
        หรือในฉบับแก้ไขเพิ่มเติมอันอาจจะมีขึ้นภายหน้า
        และการที่ท่านได้ตกลงในการใช้บริการของ www.bseviot.com
        ถือว่าท่านได่ยินยอมและเห็นด้วยกัยข้อตกลงและเงื่อนไขการใช้บริการ
      </p>
      <ul>
        <li>
          เงื่อนไขการให้บริการเว็บแอปพิเคชั่นเป็นไปตามเงื่อนไขของบริษัท
          บี.เอส.อี อิเล็คทรอนิค จำกัด หากผู้ใช้บริการทำการตอบคำถาม
          ถือว่าผู้ใช้บริการได้ยอมรับเงื่อนไขการให้บริการ
        </li>
        <li>
          bseviot.com สามารถเปลี่ยนแปลงตามเงื่อนไขการบริการได้ตามความเหมาะสม
          ซึ่งเงื่อนไขที่มีการเปลี่ยนแปลงจะถูกประกาศให้ทราบ บนเว็บแอปพิเคชั่น
        </li>
        <li>
          ผู้ใช้บริการมีหน้าที่ต้องเข้าไปเยี่ยมชมเว็บแอปพลิเคชันเป็นระยะ
          พร้อมตรวจสอบเนื้อหาข้อตกลงที่มีการเปลี่ยนแปลง และได้ประกาศไว้แล้ว
          หากผู้ใช้บริการได้มีการเข้าใช้บริการหลังจากมีการประกาศเปลี่ยนแปลงแล้ว
          ถือว่าท่านได้ยอมรับและเห็นด้วยในการเปลี่ยนแปลงเนื้อหาดังกล่าวนั้น
          และทาง bseviot.com จะไม่รับผิดชอบต่อความเสียหายใดๆ ของผู้ใช้บริการ
          อันเกิดจากการไม่ทราบเนื้อหาประกาศของข้อตกลงนั้นๆ
        </li>
        <li>
          ผู้ใช้บริการ จะต้องขอลงทะเบียนเป็นผู้ใช้บริการ
          โดยกรอกข้อมูลชื่อ-นามสกุล และข้อมูลส่วนตัวตามความเป็นจริง
          ของผู้ใช้บริการ
          และดำเนินตามขั้นตอนการขอเป็นผู้ใช้บริการด้วยข้อมูลที่เป็นความจริงทุกประการ
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="3">
        <li>รายละเอียดการบริการ</li>
      </ol>
      <p>การบริการของ www.bseviot.com มีดังต่อไปนี้</p>
      <ul>
        <li>
          การเข้าใช้เว็บแอปพิเคชั่น bseviot.com และดำเนินการลงทะเบียนเข้าสู่ระบบ
          เพื่อขอใช้เข้าเว็บแอปพิเคชั่น หรือกิจกรรมอื่นๆ บนเว็บแอปพิเคชั่น
        </li>
        <li>
          bseviot.com เป็นผู้เผยแพร่เว็บแอปพิเคชั่นบน Smart Phone หรือ
          คอมพิวเตอร์
        </li>
        <li>
          bseviot.com เป็นผู้ให้บริการระบบ IOT ที่เชื่อมต่อกับสินค้าบริษัท
          บี.เอส.อี อิเล็คทรอนิค จำกัด
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="4">
        <li>เนื้อหาการใช้บริการ</li>
      </ol>
      <p>
        www.bseviot.com จะไม่รับผิดชอบการเก็บรักษาข้อมูลใดๆ
        หรือข้อมูลการใช้งานของผู้ใช้บริการ ที่ใช้บริการอย่างผิดวัตถุประสงค์
        หรือผิดเงื่อนไขการให้บริการ หรือผิดกฎหมายไทย
        หรือกฎหมายระหว่างประเทศที่ใช้บังคับ และเป็นที่ยอมรับของนานาประเทศ
      </p>
      <ul>
        <li>
          bseviot.com จะให้บริการตามเงื่อนไขการให้บริการหรือตามกฎหมายไทย
          หรือกฎหมายระหว่างประเทศที่ใช้บังคับ และเป็นที่ยอมรับของนานาประเทศ
          เพื่อให้บริการเว็บแอปพลิเคชันที่ www.bseviot.com ได้ผลิต
          หรือได้รับสิทธิ์ให้แก่ผู้ใช้บริการ
        </li>
        <li>
          ในกรณีที่ bseviot.com
          มีความจำเป็นต้องเพิ่มเนื้อหาการให้บริการเว็บแอปพลิเคชันหรือเพื่อความจำเป็นในการบริหารเว็บแอปพลิเคชันผู้ใช้บริการตกลงยินยอมให้
          www.bseviot.com มีสิทธิ์เปลี่ยนแปลงได้เป็นครั้งๆไป
          ทั้งโดยการอัพเดทออนไลน์และออฟไลน์
        </li>
        <li>
          bseviot.com
          จะประกาศเงื่อนไขการให้บริการเว็บแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
          ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
          เกี่ยวกับเงื่อนไขการให้บริการเว็บแอปพลิเคชันอย่างสม่ำเสมอ
          ผู้ใช้บริการตกลงและให้ความยินยอมกับเงื่อนไขการให้บริการเว็บแอปพลิเคชันอัพเดทกิจกรรมหรือฟังก์ชั่นที่ให้บริการทุกประการ
          ทั้งนี้ผู้ใช้บริการ
          จะต้องตรวจสอบประกาศเงื่อนไขการให้บริการเว็บแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
          ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
          เกี่ยวกับเงื่อนไขการให้บริการเว็บแอปพลิเคชันอย่างสม่ำเสมอ
          www.bseviot.com จะไม่รับผิดชอบความเสียหายใดๆ ของผู้ใช้บริการ
          หากไม่ได้เข้ามาตรวจสอบประกาศเงื่อนไขการให้บริการเว็บแอปพลิเคชันและข้อกำหนดหรือข้อบังคับใดๆ
          ที่เกี่ยวข้องกับกฎหมายไทยหรือกฎหมายระหว่างประเทศที่ใช้บังคับและเป็นที่ยอมรับของนานาประเทศ
          เกี่ยวกับเงื่อนไขการให้บริการเว็บแอปพลิเคชันบนเว็บไซต์
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="5">
        <li>บัญชีรหัสผ่านเข้าใช้บริการ</li>
      </ol>
      <p>
        www.bseviot.com
        จะให้บัญชีและรหัสผ่านแก่ผู้ใช้บริการตามที่ผู้ใช้บริการของ
        www.bseviot.com ตั้งขึ้น โดยใช้ตัวอักษรภาษาอังกฤษ และ
        หรือตัวเลขอาราบิคในจำนวนที่กำหนดไว้ เพื่อดำเนินการใช้บริการบัญชี
        และรหัสผ่านการเข้าใช้บริการของผู้ใช้บริการ วิธีการเดียวที่
        www.bseviot.com ใช้แยกแยะผู้ใช้บริการได้
      </p>
      <ul>
        <li>
          บัญชีและรหัสผ่านจะได้รับการดูแลจัดการจาก bseviot.com
          เพื่อปกป้องข้อมูลส่วนบุคคล ซึ่ง www.bseviot.com
          ขอรับรองว่าจะเก็บข้อมูลของสมาชิกไว้เป็นความลับอย่างดีที่สุด
          มินำไปเปิดเผยที่อื่นใด โดยไม่ได้รับอนุญาต เว้นเสียแต่มีเอกสารทางกฎหมาย
          เพื่อตรวจสอบข้อมูลของผู้กระทำผิด
        </li>
        <li>
          ผู้ใช้บริการตกลงรับผิดชอบในการดูแลบัญชีและรหัสผ่านของตัวเอง
          และรับผิดชอบต่อความเสียหายในการใช้บริการ และการทดสอบ
          ในกรณีที่ข้อมูลส่วนบุคคลดังกล่าว ถูกจารกรรมโดยวิธีการทางอิเล็กทรอนิกส์
          หรือสูญหาย เสียหายอันเนื่องจากเหตุสุดวิสัยหรือไม่ว่ากรณีใดๆ ทั้งสิ้น
          ผู้ใช้บริการซึ่งเป็นผู้รับผิดชอบดูแลบัญชีจะเป็นคนรับผิดชอบทั้งหมดโดยที่
          bseviot.com จะไม่รับผิดชอบใดๆ ทั้งสิ้น
        </li>
        <li>
          bseviot.com จะไม่มีส่วนเกี่ยวข้องหรือรับผิดชอบใดๆ กับการใช้งาน มอบ
          ซื้อขาย เช่า ยืม โอน อนุญาต หรือใช้เป็นหลักประกัน
          ร่วมกันใช้หรือการถ่ายสิทธิ์ของบัญชีและรหัสผ่านไม่ว่ารูปแบบใดๆ ทั้งสิ้น
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ol start="6">
        <li>
          การใช้และการป้องกันข้อมูลส่วนบุคคล
          <ul>
            <li>
              bseviot.com มีสิทธิ์ใช้ข้อมูลของผู้ใช้บริการ
              เพื่อการใช้งานด้านต่างๆ ทางการตลาด โฆษณา ประชาสัมพันธ์ ของ
              www.bseviot.com ได้ โดยชื่อบัญชี ชื่อ-ชื่อสกุล ของผู้ใช้บริการ
              www.bseviot.com จะสามารถเปิดเผยได้ในวัตถุประสงค์
              เพื่อประโยชน์สาธารณะตามกฎหมายไทยและกฎหมายนานาประเทศที่ใช้บังคับ
            </li>
            <li>
              bseviot.com
              จะบันทึกและเก็บรักษาเนื้อหาทุกอย่างที่เกิดขึ้นระหว่างผู้ใช้บริการภายในเว็บแอปพลิเคชัน
              ดังนี้
              <ul>
                <li>
                  ข้อมูลเอกสารของบัญชีผู้ใช้/ข้อมูลของผลิตภัณฑ์ของบริษัท
                  บี.เอส.อี อิเล็คทรอนิค จำกัด
                </li>
                <li>เนื้อหาข่าวประชาสัมพัธ์ต่างๆ</li>
                <li>รายงานปัญหาต่าง ( Report Problem )</li>
              </ul>
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          ความรับผิดชอบและหน้าที่ของผู้ใช้บริการ
          <ul>
            <li>มีหน้าที่และความรับผิดอื่นๆ ตามกฎหมายที่ใช้บังคับ</li>
            <li>
              การเผยแพร่ข้อมูลส่วนตัวทั้งของตนเอง และผู้อื่นในที่สาธารณะ
              รวมถึงห้ามผู้ใช้บริการเผยแพร่ ไม่ว่าจะเป็น บทความ ถ้อยคำ รูปภาพ
              หรือ ลักษณะใดๆ อันเป็นการหมิ่นประมาท ไม่เหมาะสม ลามก อนาจาร
              หยาบคาย หรือน่ารังเกียจ
            </li>
            <li>
              ไม่กระทำผิดตามข้อกฎหมายใดๆ
              รวมถึงไม่ละเมิดในทรัพย์สินทางปัญญาหรือสิทธิอื่นในรูปแบบใดๆ
              ทั้งสิ้น
            </li>
            <li>
              ปฏิบัติตามข้อตกลงและเงื่อนไขการใช้บริการนี้ หรือกฎหมายอื่นๆ
              ที่เกี่ยวข้อง
            </li>
            <li>
              ไม่นำเข้าหรือส่งออกหรือเผยแพร่ Virus หรือทำการ Hack ใดๆ ทั้งสิ้น
            </li>
            <li>
              ไม่ประพฤติตนซึ่งเป็นอุปสรรคต่อการทำงาน
              และการให้บริการแอปพลิเคชันของ bseviot.com
            </li>
            <li>ไม่มีพฤติกรรมฉ้อโกงต่อ bseviot.com</li>
            <li>
              หากผู้ใช้บริการพบว่ามีการใช้บริการที่ผิดข้อตกลง
              และเงื่อนไขการให้บริการนี้หรือผิดกฎหมายใดๆ
              โดยบัญชีหรือรหัสผ่านซึ่งเป็นของผู้ใช้บริการ ให้ผู้ใช้บริการแจ้ง
              bseviot.com ทราบทันที
              หากพบว่าการใช้บริการนั้นเกิดจากความประมาทเลินเล่อของผู้ใช้บริการเอง
              www.bseviot.com จะไม่รับผิดชอบใดๆทั้งสิ้น
            </li>
            <li>
              ผู้ใช้บริการ จะต้องรับผิดชอบในเนื้อหา และ/หรือสื่ออื่นใด
              ในบัญชีของผู้ใช้บริการเอง ในกรณีเกิดข้อโต้แย้ง/ข้อพิพาท กับบุคคล,
              กลุ่มบุคคล หรืออื่นๆ ทาง bseviot.com จะไม่รับผิดชอบใดๆ ทั้งสิ้น
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="8">
        <li>
          การยกเว้นความรับผิด
          <ul>
            <li>
              bseviot.com จะถูกยกเว้นความรับผิดชอบในกรณีที่ www.bseviot.com
              ไม่สามารถให้บริการได้เนื่องจากเหตุสุดวิสัย
            </li>
            <li>
              bseviot.com
              จะไม่รับผิดชอบเกี่ยวกับการระงับหรือข้อบกพร่องในการใช้บริการเนื่องมาจากผลทางกฎหมายใดหรือการกระทำผิดกฎหมายของผู้ใช้บริการ
            </li>
            <li>
              bseviot.com
              มีสิทธิ์ยกเว้นความรับผิดในกรณีที่เกิดเหตุขัดข้องทางไฟฟ้าและโทรคมนาคม
            </li>
            <li>
              bseviot.com
              ยกเว้นความรับผิดของตนในกรณีที่การให้บริการหยุดไปเนื่องจากเหตุการณ์ที่หลีกเลี่ยงไม่ได้
              เช่น การแก้ไข เปลี่ยนแปลง การตรวจเช็ค
              และการก่อสร้างอุปกรณ์สำหรับการให้บริการซึ่งประกาศไปล่วงหน้าแล้ว
            </li>
            <li>
              bseviot.com จะไม่รับผิดชอบจากการ Login
              ล่าช้าและความเสียหายที่เกิดขึ้นจากระบบการเชื่อมต่อล่าช้าได้
            </li>
            <li>
              bseviot.com ไม่มีหน้าที่จะเข้ามาดูแลเกี่ยวกับปัญหาระหว่าง
              ผู้ใช้บริการด้วยกัน www.bseviot.com
              ไม่มีหน้าที่ที่ต้องรับผิดชอบเกี่ยวกับค่าเสียหายที่เกิดขึ้น
            </li>
            <li>
              bseviot.com บางส่วนของการบริการอาจจะมีการให้บริการของ
              www.bseviot.com อื่นรวมอยู่ด้วย www.bseviot.com
              จะไม่รับผิดชอบเกี่ยวกับความเสียหายที่เกิดจากการบริการที่มาจาก
              www.bseviot.com อื่นๆ
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="9">
        <li>
          ข้อจำกัดในการใช้บริการ
          <ul>
            <li>
              บัญชีและรหัสผ่านทั้งหมด รวมไปถึงข้อมูลต่างๆ ที่อยู่ในบัญชี
              เป็นทรัพย์สินของ bseviot.com
              ไม่ว่าข้อมูลดังกล่าวจะได้มาด้วยวิธีการใดก็ตาม โดย www.bseviot.com
              เพียงแต่ให้สิทธิแก่ผู้ใช้ในการใช้บัญชี รหัสผ่าน
              ภายในระยะเวลาที่ให้บริการเท่านั้น
            </li>
            <li>
              bseviot.com
              จะไม่รับผิดชอบใดๆทั้งสิ้นต่อปัญหาที่เกิดขึ้นระหว่างผู้ใช้บริการด้วยกัน
            </li>
            <li>
              bseviot.com สามารถระงับการใช้บัญชีของผู้ใช้บริการได้
              หากฝ่าฝืนข้อกำหนดหน้าที่ของผู้ใช้บริการอันเนื่องจาการใช้บัญชีในทางมิชอบจากบุคคลที่สาม
              หรือมีจากความเสียหายในการใช้งานบริการเกิดแก่
              ผู้ใช้บริการอันเนื่องมาจากการรั่วไหลของบัญชีหรือรหัสผ่านในการดูแลของผู้ใช้บริการเอง
            </li>
            <li>
              bseviot.com สามารถระงับการใช้งานบัญชี
              และข้อมูลทุกอย่างภายในเว็บแอปพลิเคชันของผู้ใช้บริการจากการพิจารณาตามความเหมาะสมในการควบคุมหรือหมายกำหนดการต่างๆ
              ของเว็บแอปพลิเคชัน
            </li>
            <li>
              bseviot.com
              เป็นผู้ถือสิทธิและอำนาจทั้งหมดในข้อมูลทุกประการในเว็บแอปพลิเคชันของผู้ใช้บริการ
              ในกรณีที่ www.bseviot.com
              เล็งเห็นความจำเป็นในการควบคุมหรือปรับปรุงเว็บแอปพลิเคชัน
              www.bseviot.com สามารถเพิ่มเติม ลบ หรือเปลี่ยนแปลงได้
            </li>
            <li>
              เว็บแอฟพิเคชั่น bseviot.com
              สามารถติดตั้งและทำงานได้ทุกระบบปฏิบัติการแค่มี เว็บ
              <em>บราวเซอร์</em>
            </li>
          </ul>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol start="10">
        <li>สิทธในทางทรัพย์สินทางปัญญา</li>
      </ol>
      <p>
        www.bseviot.com
        มีความเป็นเจ้าของหรือมีกรรมสิทธิ์ในทรัพย์สินทางปัญญาบนเว็บแอปพลิเคชัน
        www.bseviot.com แต่เพียงผู้เดียว
        ผลงานหรือข้อมูลทั้งหมดบนเว็บแอปพลิเคชันนี้ ไม่ว่าจะเป็นในรูปแบบลิขสิทธิ์
        สิทธิบัตร
        เครื่องหมายการค้าและสิทธิในทรัพย์สินทางปัญญากรรมสิทธิ์หรือสิทธิอื่นๆ
        ห้ามมิให้ทำซ้ำ โอน เปลี่ยนแปลง แก้ไข เผยแพร่ หรือในรูปแบบอื่นๆ
        ไม่ว่าทั้งหมดหรือแต่บางส่วน โดยไม่ได้รับอนุญาตเป็นลายลักษณ์อักษรจาก
        www.bseviot.com อย่างเด็ดขาด ผู้ละเมิดจะถูกดำเนินคดีตามกฎหมายอย่างสูงสุด
      </p>
      <p>&nbsp;</p>
      <ol start="11">
        <li>กฎหมายที่ใช้บังคับ</li>
      </ol>
      <p>
        การตีความและการประยุกต์ใช้ข้อตกลงและเงื่อนไขการให้บริการนี้ให้เป็นไปตามกฎหมายไทย
        ที่มีผลใช้บังคับ
      </p>
    </div>
  );
}
export default TermnCond;
