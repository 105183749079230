import "./../App.css";
import { useHistory } from "react-router-dom";
import "./Main.css";
import { IconButton, TextField, Button } from "@material-ui/core";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { Navbar, GroupListCard } from "./minicomponents";
import React, { useState, useEffect } from "react";

import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Backend
import config from "./config.json";
import { deleteCookie, accessCookie } from "./CookieManagement.js";

function GroupDashboard() {
  const history = useHistory();
  let [userdata, setUserdata] = useState(null);
  const [filtered, setFiltered] = useState("");
  let [adddevicestate, setAdddevicestate] = useState(false);
  let [addgroupdata, setAddgroupdata] = useState({
    groupname: "",
    description: "",
  });
  let [validgroupname, setValidgroupname] = useState(true);
  async function checkUserData(access_tok) {
    let response = await fetch(config.backend_url + "/be/users/me", {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + access_tok,
      },
    });
    //
    if (response.status === 200) {
      let data = await response.json();
      setUserdata(data);
    } else {
      deleteCookie("bsetok");
      history.push("/");
    }
  }
  const handleadddeviceChange = (e) => {
    const { name, value } = e.target;
    setAddgroupdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (name === "groupname") {
      if (
        !/^[0-9a-zA-Z_]+?$/.test(value) ||
        value === "" ||
        Object.keys(userdata.grouplist).includes(value)
      ) {
        setValidgroupname(false);
      } else {
        setValidgroupname(true);
      }
    }
  };
  async function sendadddevicerequest() {
    if (addgroupdata.groupname === "") {
      setValidgroupname(false);
    } else {
      let response = await fetch(config.backend_url + "/be/addgroup", {
        method: "POST",
        mode: "cors",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + accessCookie("bsetok"),
        },
        body: JSON.stringify(addgroupdata),
      });
      //
      if (response.status === 200) {
        checkUserData(accessCookie("bsetok"));
        setAdddevicestate(false);
      } else {
        let data = await response.json();
        document.getElementById("adddevicewarning").innerHTML = data.detail;
      }
    }
  }
  useEffect(() => {
    //setAccesstoken(accessCookie("tok"));
    if (accessCookie("bsetok") !== "") {
      checkUserData(accessCookie("bsetok"));
      localStorage.setItem("groupname", "");
    } else {
      history.push("/");
    }
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  return (
    <div>
      <Modal show={adddevicestate} backdrop="static" centered>
        {/* <Backdrop open={adddevicestate} className={classes.backdrop}> */}
        <div
          style={{ display: "flex", justifyContent: "center", minWidth: "90%" }}
        >
          <div className="popup-card2">
            <div className="text-title">Create Group</div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  error={!validgroupname}
                  label="Group Name"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="groupname"
                  value={addgroupdata.groupname}
                  onChange={handleadddeviceChange}
                  helperText={
                    !validgroupname &&
                    ((addgroupdata.groupname === "" &&
                      "Please fill group name") ||
                      (!/^[0-9a-zA-Z_]+?$/.test(addgroupdata.groupname) &&
                        "Please fill group name only 0-9 a-z A-Z _") ||
                      (Object.keys(userdata.grouplist).includes(
                        addgroupdata.groupname
                      ) &&
                        "This groupname is already used"))
                  }
                />
              </div>
            </div>
            <div className="textfield">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <TextField
                  label="Description"
                  className="textfield"
                  style={{ marginLeft: "8px" }}
                  name="description"
                  value={addgroupdata.description}
                  onChange={handleadddeviceChange}
                />
              </div>
            </div>
            <div className="space-text">
              <div
                className="text-option"
                id="adddevicewarning"
                style={{ color: "red" }}
              ></div>
            </div>
            <Button
              variant="contained"
              size="large"
              color="primary"
              disableElevation
              style={{ width: "100%", marginBottom: "8px" }}
              onClick={sendadddevicerequest}
            >
              Create Group
            </Button>
            <Button
              variant="outlined"
              size="large"
              color="default"
              style={{ width: "100%" }}
              onClick={() => {
                setAdddevicestate(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
        {/* </Backdrop> */}
      </Modal>
      <div className="background">
        <div className="container page-block">
          <Navbar />
          <div className="device-card">
            {userdata && (
              <div className="data-card">
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="text-title">Group</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "32px",
                            width: "32px",
                            height: "32px",
                            marginLeft: "10px",
                          }}
                          onClick={(e) => {
                            setAdddevicestate(true);
                            setValidgroupname(true);
                            setAddgroupdata({
                              groupname: "",
                              description: "",
                            });
                          }}
                        >
                          <PlaylistAddIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </div>
                    </div>
                    <div style={{ height: "64px" }}>
                      <TextField
                        label="Search bar"
                        className="textfield"
                        name="Search bar"
                        value={filtered}
                        onChange={(e) => setFiltered(e.target.value)}
                        style={{ marginBottom: "16px" }}
                      />
                    </div>
                    <div
                      style={{
                        height: "52vh",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {userdata &&
                        Object.keys(userdata.grouplist).map((group, index) => {
                          if (
                            !filtered ||
                            filtered === "" ||
                            userdata.grouplist[group].description.includes(
                              filtered
                            ) ||
                            group.includes(filtered)
                          ) {
                            return (
                              <GroupListCard
                                key={"device" + index}
                                title={group}
                                //status={device}
                                description={
                                  userdata.grouplist[group].description
                                }
                                icon={<FolderOpenIcon />}
                                // link={"/dashboard?groupname=" + group}
                                groupname={group}
                              />
                            );
                          }
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <LinechartApex /> */}
      </div>
    </div>
  );
}

export default GroupDashboard;
