import {
  DeviceBSACT2A1FIOT,
  DeviceBSACT2A1FAIOT,
  DeviceBSE4A1FIOT,
  DeviceBSESIMCOWV1,
  DeviceBSESIMCOWV2,
  DeviceBSE2ARS485IOT,
} from "../devicesdashboard";
export default function ScreenDevice(props) {
  let userdata = props.userdata;
  let serialid = props.serialid;
  let setState = props.setState;
  let machinetype = props.machinetype;
  if (machinetype === "BSACT-2A1F-IOT") {
    return (
      <DeviceBSACT2A1FIOT
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else if (machinetype === "BSACT-2A1F-AIOT") {
    return (
      <DeviceBSACT2A1FAIOT
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else if (machinetype === "BSE-4A1F-IOT") {
    return (
      <DeviceBSE4A1FIOT
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else if (machinetype === "BSE-SIM-COW-V1") {
    return (
      <DeviceBSESIMCOWV1
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else if (machinetype === "BSE-SIM-COW-V2") {
    return (
      <DeviceBSESIMCOWV2
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else if (machinetype === "BSE-2ARS485-IOT") {
    return (
      <DeviceBSE2ARS485IOT
        userdata={userdata}
        serialid={serialid}
        setState={setState}
      />
    );
  } else {
    return <div></div>;
  }
}
