import "./../../App.css";
import { useHistory } from "react-router-dom";
import "../Main.css";
import { IconButton } from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

function DeviceListCard(props) {
  const { icon, link, machinetype, serialname, description } = props;
  const history = useHistory();

  return (
    <div
      style={{
        marginBottom: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          className="white-block"
          onClick={() => history.push(link)}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "8px 8px 4px 4px",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "auto",
              overflow: "hidden",
            }}
          >
            <div style={{ marginLeft: "8px", marginRight: "8px" }}>{icon}</div>
            <img
              src={"/deviceimage/" + machinetype + ".png"}
              style={{ height: "40px", width: "40px" }}
              alt=""
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                alignItems: "flex-start",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                marginLeft: "8px",
                marginRight: "8px",
                width: "auto",
              }}
            >
              <div className="text-status-title">{serialname}</div>
              <div className="text-status-status">{description}</div>
            </div>
          </div>
          <div style={{ marginLeft: "8px", marginRight: "8px" }}>
            <IconButton
              style={{
                backgroundColor: "#eeeeee",
                borderRadius: "32px",
                width: "32px",
                height: "32px",
              }}
            >
              <ArrowForwardIosIcon style={{ width: "16px", height: "16px" }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceListCard;
