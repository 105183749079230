import "./../../App.css";
import { useHistory } from "react-router-dom";
import "../Main.css";
import { IconButton } from "@material-ui/core";
// import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
// import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from "@material-ui/icons/Settings";
import EmailIcon from "@material-ui/icons/Email";

import { deleteCookie } from "../CookieManagement.js";
function Navbar() {
  const history = useHistory();
  async function Logout() {
    deleteCookie("bsetok");
    history.push("/");
  }
  return (
    <div className="container nav-bar">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <div className="nav-logo-block">
          <img src="/logo_bse.png" className="nav-logo" alt="" />
        </div>
      </div>
      <div className="nav-button-block">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              className="nav-button"
              onClick={() => history.push("/home")}
            >
              <HomeIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              className="nav-button"
              onClick={() => history.push("/contact")}
            >
              <EmailIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              className="nav-button"
              onClick={() => {
                history.push("/Settings");
              }}
            >
              {/* <PermIdentityIcon /> */}
              <SettingsIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton className="nav-button" onClick={Logout}>
              <ExitToAppIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
