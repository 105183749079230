var CryptoJS = require("crypto-js");
//var key = "1234567890123456"; // TODO change to something with more entropy
//CryptoJS.enc.Utf8.parse(key)
export function AESencrypt(msgString, key) {
  // msgString is expected to be Utf8 encoded
  var iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(
    msgString,
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: iv,
    }
  );
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export function AESdecrypt(ciphertextStr, key) {
  var ciphertext = CryptoJS.enc.Base64.parse(ciphertextStr);

  // split IV and ciphertext
  var iv = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4); // delete 4 words = 16 bytes
  ciphertext.sigBytes -= 16;

  // decryption
  var decrypted = CryptoJS.AES.decrypt(
    { ciphertext: ciphertext },
    CryptoJS.enc.Utf8.parse(key),
    {
      iv: iv,
    }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}
//console.log(encrypt("test", key));
//console.log(decrypt("r5g8GBf8F/FAd7MYen5fQ8jhKIn44eTPIOe4yI51Sfw=", key));
